<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!$store.state.departments">
    </v-skeleton-loader>
    <!-- / loader before data load -->
    <v-card
      v-if="$store.state.departments"
      outlined
      elevation="0"
      class="mb-10"
    >
      <v-card-title>
        {{ keys.departments_page_name }}
        <v-spacer></v-spacer>
        <v-dialog max-width="500" v-model="dialog">
          <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip bottom nudge-bottom="-5">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-bind="attrs"
                  v-on="{ ...dialog, ...tooltip }"
                  elevation="0"
                  color="primary"
                >
                  {{ keys.departments_page_btn_add }}
                </v-btn>
              </template>
              <span>{{ keys.departments_page_btn_add }}</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="font-weight-bold">{{
              dialogTitle
            }}</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-alert
                class="text-center"
                v-if="err"
                dense
                border="left"
                type="error"
              >
                {{ errText }}
              </v-alert>
              <!--/alert -->

              <v-tabs class="mb-3" v-model="tabs" background-color="gray">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab
                  class="mb-1"
                  v-model="tabs"
                  v-for="(lang, i) in this.$store.getters.languages"
                  :key="i"
                >
                  {{ lang.native }}
                </v-tab>
              </v-tabs>
              <!--/tabs -->
              <v-tabs-items v-model="tabs">
                <v-tab-item
                  v-for="(lang, i) in this.$store.getters.languages"
                  :key="i"
                >
                  <v-row
                    class="mb-1"
                    :dir="lang.direction"
                    :class="[
                      { 'text-end': lang.direction == 'ltr' },
                      { 'text-start': lang.direction == 'rtl' },
                    ]"
                  >
                    <v-col cols="12">
                      <v-text-field
                        v-model="editItemObj.name[lang.locale]"
                        hide-details
                        :error="i == 0 ? errName : null"
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                      >
                        <template slot="label">
                          <strong class="error--text">*</strong>
                          {{ keys.departments_page_kind_name }}
                        </template>
                      </v-text-field>
                    </v-col>
                    <!--/col  name -->
                    <v-col cols="12" v-if="tabs == 0">
                      <v-file-input
                        v-model="editItemObj.image"
                        hide-details
                        :error="errImage"
                        label="اسم  الاصلى للغة"
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                      >
                        <template slot="label">
                          <strong class="error--text">*</strong>
                          {{ keys.departments_page_kind_image }}
                        </template>
                      </v-file-input>
                    </v-col>
                    <!--/col  native name  -->
                    <!--/col  active    -->
                  </v-row>
                  <!--/row -->
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
            <!--/card text -->
            <v-card-actions>
              <v-btn
                elevation="0"
                small
                text
                color="primary"
                :loading="load"
                @click="save()"
                class="font-weight-bold"
              >
                {{ keys.departments_page_save_btn }}
              </v-btn>
              <!--/ save -->

              <v-btn
                @click="close()"
                small
                text
                color="red"
                class="font-weight-bold"
              >
                {{ keys.departments_page_close_btn }}</v-btn
              >
              <!--/close -->
            </v-card-actions>
            <!--/action -->
          </v-card>
        </v-dialog>
        <!--/dialog -->
      </v-card-title>
      <!--/card title -->
      <v-data-table :headers="headers" :items="desserts" :mobile-breakpoint="0">
        <template v-slot:[`item.image`]="{ item }">
          <img width="80" :src="item.src" />
        </template>
        <!--/end name item -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="editDepartment(item)" small icon>
            <v-icon small>edit </v-icon>
          </v-btn>
          <!--/edit language -->
          <v-btn @click="getDeleteId(item.id)" icon small>
            <v-icon small>delete </v-icon>
          </v-btn>
          <!--/delete language -->
        </template>
        <!--/ end action items -->
      </v-data-table>
      <!-- / data table -->
    </v-card>
    <!--/card -->

    <v-dialog max-width="300" v-model="deletePop">
      <v-card>
        <v-card-title class="text-center d-block"
          >{{ keys.departments_page_delete_popup_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="loadDelete"
            :loading="loadDelete"
            @click="deleteDep()"
            depressed
            color="error"
          >
            {{ keys.departments_page_delete_popup_delete_btn }}
          </v-btn>
          <v-btn @click="deletePop = false" depressed>{{
            keys.departments_page_delete_popup_close_btn
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
</template>

<script>
export default {
  name: "departments",
  data() {
    return {
      tabs: null,
      err: false,
      errText: "",

      errName: false,
      errImage: false,

      load: false,

      dialog: false,
      indexItem: -1,

      imageFile: null,

      editItemObj: {
        name: {},
        image: null,
      },
      defualtItem: {
        name: {},
        image: null,
      },
      deletePop: false,
      loadDelete: false,
      deleteId: null,
    };
  },
  watch: {
    dialog(newvalue, oldvalue) {
      if (!newvalue && oldvalue) {
        this.close();
      }
    },
  },
  computed: {
    dialogTitle() {
      return this.indexItem == -1
        ? this.keys.departments_page_add_title
        : this.keys.departments_page_edit_title;
    },
    desserts() {
      return this.$store.state.departments || [];
    },
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    headers() {
      return [
        {
          text: this.keys.departments_page_kind_name,
          value: "name_locale",
          align: "center",
        },
        {
          text: this.keys.departments_page_kind_image,
          value: "image",
          align: "center",
        },
        {
          text: this.keysdepartments_page_actions,
          value: "actions",
          align: "center",
        },
      ];
    },
  },
  methods: {
    // fade the dialog then geting the Language   item which want to edit  then assign for editITemObj
    editDepartment(item) {
      this.indexItem = this.desserts.indexOf(item);
      this.editItemObj = Object.assign({}, item);
      this.dialog = true;
    },
    // make validation before request edit or create if name empty || desc empty
    valid() {
      let default_lang_locale = this.$store.getters.languages.find(
        (e) => e.is_default
      ).locale;
      if (!this.editItemObj.name[default_lang_locale]) {
        this.errText = this.keys.departments_page_empty_name;
        this.err = true;
        this.errName = true;
        this.tabs = 0;
        return false;
      } else {
        this.err = false;
        this.errName = false;
      }

      if (!this.editItemObj.image && this.indexItem == -1) {
        this.errText = this.keys.departments_page_empty_image;
        this.err = true;
        this.errImage = true;
        this.tabs = 0;
        return false;
      } else {
        this.err = false;
        this.errImage = false;
      }
      return true;
    },

    // save the edit or create
    save() {
      if (this.valid()) {
        let formData = new FormData();
        Object.entries(this.editItemObj.name).forEach((e) => {
          formData.append(`name[${e[0]}]`, this.editItemObj.name[e[0]]);
        });
        if (this.editItemObj.image != null) {
          formData.append("image", this.editItemObj.image);
        }

        // check if  everything is valid
        this.load = true; // make save button loading
        if (this.indexItem > -1) {
          //  this condetion  to check if user want to edit or create
          let config = {
            method: "post",
            url: `departments/${this.editItemObj.id}?_method=PUT`,
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`,
              "Content-Type": "multipart/form-data",
            },
            data: formData,
          };
          this.axios(config)
            .then((res) => {
              this.$store.dispatch("departments");
              this.close();
            })
            .catch((err) => {
              this.errorName = true;
              this.load = false;
            });
        } else {
          let config = {
            method: "post",
            url: "departments",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${this.$store.state.token}`,
              "Content-Type": "multipart/form-data",
            },
            data: formData,
          };
          this.axios(config)
            .then((res) => {
              this.$store.dispatch("departments"); // adding the new object to render it
              this.close();
            })
            .catch((err) => {
              // this catch resonse the name is exists
              this.errorName = true;
              this.load = false;
            });
        }
      }
    },
    close() {
      // close the edit & create window after  saveing or closeing
      this.load = false; // remove save loading
      this.dialog = false; // close window
      this.errName = false;
      this.errImage = false;
      this.imageFile = null;
      this.editedIndex = -1;

      this.$nextTick(() => {
        Object.keys(this.editItemObj).forEach((e) => {
          this.editItemObj[e] = {};
        }); // back the editItemObj to defualt
      });
    },
    // get delete id then assign for prop to use it
    getDeleteId(id) {
      this.deleteId = id;
      this.deletePop = true;
    },
    // delete languages
    deleteDep() {
      this.loadDelete = true; // make delete button start loading
      let config = {
        method: "delete",
        url: `departments/${this.deleteId}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.$store.state.token}`,
          "Content-Type": "application/json",
        },
      };
      this.axios(config).then((res) => {
        let findindex = this.desserts.findIndex((e) => e.id == this.deleteId); // get index of  category delete for delete form desserts
        this.desserts.splice(findindex, 1);
        this.deletePop = false;
        this.loadDelete = false;
      });
    },
  },
  mounted() {
    this.$store.dispatch("departments");
    let vm = this;
    window.onkeyup = function (e) {
      // make delete action in enter key
      if (e.key == "Enter") {
        vm.deleteDep();
      }
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input--switch .v-input--selection-controls__input {
    margin: 0 auto;
  }
}
</style>
