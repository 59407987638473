var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"text-center",attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',{staticClass:"d-flex"},[_vm._v(" صلاحيات الموظفين "),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","nudge-bottom":"-5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"0","color":"primary"}},'v-btn',attrs,false),Object.assign({}, dialog, tooltip)),[_vm._v("اضافة صلاحية ")])]}}],null,true)},[_c('span',[_vm._v("اضافة")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.dialogTitle))])]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":" نوع الموظف","hide-details":""},model:{value:(_vm.editItemObj.phone),callback:function ($$v) {_vm.$set(_vm.editItemObj, "phone", $$v)},expression:"editItemObj.phone"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":" وصف","hide-details":""},model:{value:(_vm.editItemObj.whatsapp),callback:function ($$v) {_vm.$set(_vm.editItemObj, "whatsapp", $$v)},expression:"editItemObj.whatsapp"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.items,"chips":"","label":"الصلاحيات","multiple":"","item-text":"name","item-value":"id","hide-details":""},model:{value:(_vm.editItemObj.permations),callback:function ($$v) {_vm.$set(_vm.editItemObj, "permations", $$v)},expression:"editItemObj.permations"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"small":"","color":"red","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" الغاء ")]),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"primary","text":"","small":""},on:{"click":function($event){_vm.save(), (_vm.dialog = false)}}},[_vm._v(" حفظ ")])],1)],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"item-key":"id","sort-by":"calories","hide-default-footer":"","mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.employeesCount",fn:function(ref){
var item = ref.item;
return [(item.employeesCount == 0)?_c('v-chip',{attrs:{"color":"red accent-1 white--text","x-small":""}},[_vm._v(" لايوجد ")]):_vm._e(),(item.employeesCount > 0)?_c('span',[_vm._v(" "+_vm._s(item.employeesCount)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","icon":"","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" edit ")])],1),_c('v-btn',{attrs:{"fab":"","icon":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" delete ")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }