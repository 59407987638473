<template>
  <v-container>
    <v-row class="mb-1">
      <v-col cols="12" md="4">
        <v-card elevation="0" outlined class="analayze rounded-lg">
          <v-card-text>
            <div class="d-flex justify-space-between mb-5">
              <h2 class="h3 analayze__title red--text">2000</h2>
              <v-icon class="analayze__icon red--text"> circle</v-icon>
            </div>
            <div class="d-flex justify-space-between">
              <h2 class="analayze__title analayze__title--text">
                اجمالي المدفوعات
              </h2>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card elevation="0" outlined class="analayze rounded-lg">
          <v-card-text>
            <div class="d-flex justify-space-between mb-5">
              <h2 class="h3 analayze__title primary--text">2000</h2>
              <v-icon class="analayze__icon primary--text"> circle</v-icon>
            </div>
            <div class="d-flex justify-space-between">
              <h2 class="analayze__title analayze__title--text">
                اجمالي المتبقي
              </h2>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card elevation="0" outlined class="analayze rounded-lg">
          <v-card-text>
            <div class="d-flex justify-space-between mb-5">
              <h2 class="h3 analayze__title yellow--text">2000</h2>
              <v-icon class="analayze__icon yellow--text"> circle</v-icon>
            </div>
            <div class="d-flex justify-space-between">
              <h2 class="analayze__title analayze__title--text">
                اجمالي الاشتراكات
              </h2>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!--/ analayze -->
    <v-card class="mb-3" elevation="0" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" class="mb-2 d-flex justify-space-between">
            <v-dialog
              ref="dialog"
              v-model="dateModal"
              :return-value.sync="dates"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="التاريخ"
                  append-icon="mdi-calendar"
                  hide-details
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>

              <v-date-picker range v-model="dates" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateModal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="بحث"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="text-center" elevation="0" outlined>
      <v-card-title class="font-weight-bold d-flex"> المدفوعات </v-card-title>
      <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        sort-by="calories"
        no-data-text="لا يوجد نتائج "
        no-results-text="لا يوجد نتائج بحث"
        :footer-props="$store.state.dataTableFooterProps"
      >
        <template style="max-width: 300px" v-slot:[`item.sentTo`]="{ item }">
          <v-btn
            rounded
            elevation="0"
            class="me-1"
            @click="sentToArr(item.sentTo)"
            small
          >
            {{ item.sentTo[0].name }}
          </v-btn>
          <v-chip
            class="px-2"
            color="success"
            small
            v-if="item.sentTo.length > 1"
          >
            <v-icon small class="material-icon">add</v-icon>
            {{ item.sentTo.length - 1 }}
          </v-chip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            x-small
            class='px-2'
            :class="[
              { 'red accent-1 white--text': item.status == 0 },
              { 'gray grey darken-1 black--text': item.status == 1 },
              { success: item.status == 2 },
            ]"
          >
            <span v-if="item.status == 0">فشل الارسال</span>
            <span v-else-if="item.status == 1">لم يتم بعد</span>
            <span v-else>تم الأرسال</span>
          </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y :close-on-content-click="closeOnContentClick">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                fab
                small
                v-bind="attrs"
                v-on="on"
                @click="closeOnContentClick = !closeOnContentClick"
              >
                <v-icon small>call</v-icon>
              </v-btn>
            </template>
            <v-list class="text-center">
              <v-list-item outlined href="tel:021231231" link>
                <v-list-item-title class="subtitle-2 font-weight-bold"
                  >اتصل الان</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="subtitle-2"
                  >02132123154</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu offset-y :close-on-content-click="closeOnContentClick2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                class="mx-2"
                icon
                v-bind="attrs"
                v-on="on"
                @click="closeOnContentClick = !closeOnContentClick2"
              >
                <v-icon small>whatsapp</v-icon>
              </v-btn>
            </template>
            <v-list class="text-center">
              <v-list-item href="tel:021231231" link>
                <v-list-item-title class="subtitle-2 font-weight-bold"
                  >اتصل الان</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="subtitle-2"
                  >02132123154</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn fab small icon :to="{ name: 'Home', query: { id: item.id } }">
            <v-icon small>visibility</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!--start dialog viwe sent to  names -->
    <v-dialog max-width="500" v-model="sentToDialog.modal">
      <v-card>
        <v-card-title class="font-weight-bold">عرض المرسل اليهم</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-chip-group column>
            <v-chip v-for="(a, i) in sentToDialog.names" :key="i">
              {{ a.name }}
              <span class="ms-2" v-if="!a.sent">
                <v-icon class="red--text" small>cancel</v-icon>
              </span>
              <span class="ms-2" v-if="a.sent">
                <v-icon class="primary--text" small>check_circle</v-icon>
              </span>
            </v-chip>
          </v-chip-group>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="font-weight-bold"
            text
            small
            color="sencodary"
            @click="sentToDialog.modal = false"
            >الغاء</v-btn
          >
        </v-card-actions>
      </v-card>
      <!--/card-->
    </v-dialog>
    <!--End dialog viwe sent to  names -->
  </v-container>
</template>

<script>
export default {
  name: "messages",
  data() {
    return {
      dates: [],
      dateModal: false,
      sentToDialog: {
        modal: false,
        names: [],
      }, // for view sent to
      obj: "",
      search2: null,
      menu: false,
      items: [
        { id: 1, name: "mohaned" },
        { id: 2, name: "ahmed" },
        { id: 3, name: "kahled" },
        { id: 4, name: "saed" },
        { id: 5, name: "mustafa" },
        { id: 6, name: "taher" },
      ],
      sendTo: [],
      plans: ["plan1", "plan2"],
      closeOnContentClick: true,
      closeOnContentClick2: true,
      dialog: false,
      search: "",
      dialog: false,
      editItemObj: {
        messageNumber: "",
        sentTo: [],
        sender: "",
        status: null,
        date: "",
        message: "",
      },
      defualtItem: {
        messageNumber: "",
        sentTo: [],
        sender: "",
        status: null,
        date: "",
        message: "",
      },
      indexItem: -1,
      headers: [
        { text: "رقم الدفعة", value: "payNumber", align: "center" },
        { text: "تاريخ الدفعة", value: "date", align: "center" },
        { text: "اسم الشركة", value: "companyName", align: "center" },
        { text: " اسم صاحب الشركة", value: "ownerName", align: "center" },
        { text: "حالة الدفعة", value: "status", align: "center" },
        { text: "اجراءات", value: "actions", sortable: false, align: "center" },
      ],
      desserts: [
        {
          payNumber: 123,
          companyName: "hassan ahmed",
          ownerName: "hassan ahmed",
          status: 0,
          date: "20-20-2020 12:00",
        },
        {
          payNumber: 2143,
          companyName: "hassan ahmed",
          ownerName: "hassan ahmed",
          status: 1,
          date: "20-20-2020 12:00",
        },
        {
          payNumber: 2163,
          companyName: "hassan ahmed",
          ownerName: "hassan ahmed",
          status: 2,
          date: "20-20-2020 12:00",
        },
      ],
    };
  },
  methods: {
    inp(a) {
      console.log(a);
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
};
</script>
