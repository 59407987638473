var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-3",attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.translations.title)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.new_group()}}},[_vm._v("اضافة مجموعة")])],1),_c('v-card-text',[_c('v-list',_vm._l((_vm.additions.additions),function(addition,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('span',[(addition.is_public)?_c('v-icon',{attrs:{"small":""}},[_vm._v("lock")]):_vm._e(),_vm._v(" "+_vm._s(!addition.is_public ? addition.name[_vm.default_lang_locale] : ".......")+" ")],1)]),_c('v-list-item-action',[_c('div',{staticClass:"d-flex px-2"},[_c('v-btn',{attrs:{"max-width":"28px","small":"","icon":""},on:{"click":function($event){return _vm.edit_additions(i, addition)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1),(!addition.is_public)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('remove_group_addition', {
                    id: addition.id,
                    addition_index: i,
                  })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1):_vm._e()],1)])],1)}),1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Group name")]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-3"},[(_vm.err.prop)?_c('v-alert',{staticClass:"text-center",attrs:{"dense":"","border":"left","type":"error"}},[_vm._v(" "+_vm._s(_vm.err.text)+" ")]):_vm._e(),(!_vm.dialog_additions.is_public)?_c('v-tabs',{staticClass:"mb-3",attrs:{"background-color":"gray"},model:{value:(_vm.grouptabs),callback:function ($$v) {_vm.grouptabs=$$v},expression:"grouptabs"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((this.$store.getters.languages),function(lang,i){return _c('v-tab',{key:i,model:{value:(_vm.grouptabs),callback:function ($$v) {_vm.grouptabs=$$v},expression:"grouptabs"}},[_vm._v(" "+_vm._s(lang.native)+" ")])})],2):_vm._e(),(_vm.dialog_additions.is_public)?_c('h3',{staticClass:"my-3 black--text"},[_vm._v(_vm._s(_vm.translations.group_title))]):_vm._e(),(!_vm.dialog_additions.is_public)?_c('v-tabs-items',{model:{value:(_vm.grouptabs),callback:function ($$v) {_vm.grouptabs=$$v},expression:"grouptabs"}},_vm._l((this.$store.getters.languages),function(lang,i){return _c('v-tab-item',{key:i},[_c('v-row',{staticClass:"align-end pb-2 me-1 me-md-0",class:[
                { 'text-end': lang.direction == 'ltr' },
                { 'text-start': lang.direction == 'rtl' } ],attrs:{"dir":lang.direction}},[_c('v-col',{attrs:{"cols":"6","sm":"5"}},[_c('v-text-field',{class:[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' } ],attrs:{"prepend-icon":_vm.dialog_additions.err_group_name && _vm.grouptabs == 0
                      ? 'error_outline'
                      : '',"hide-details":"","error":_vm.grouptabs == 0 ? _vm.dialog_additions.err_group_name : null,"value":Object.keys(_vm.dialog_additions).length != 0
                      ? _vm.dialog_additions.name[lang.locale]
                      : ''},on:{"blur":function (ev) { return _vm.$set(
                        _vm.dialog_additions.name,
                        lang.locale,
                        ev.target.value
                      ); }}},[_c('template',{slot:"label"},[(_vm.grouptabs == 0)?_c('strong',{staticClass:"error--text"},[_vm._v("*")]):_vm._e(),_vm._v(" name group ")])],2)],1)],1)],1)}),1):_vm._e(),_c('v-tabs',{staticClass:"mb-3",attrs:{"background-color":"gray"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((this.$store.getters.languages),function(lang,i){return _c('v-tab',{key:i,model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_vm._v(" "+_vm._s(lang.native)+" ")])})],2),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((this.$store.getters.languages),function(lang,i){return _c('v-tab-item',{key:i},_vm._l((_vm.dialog_additions.children),function(add,i){return _c('v-row',{key:i,staticClass:"align-end pb-2 me-1 me-md-0",class:[
                { 'text-end': lang.direction == 'ltr' },
                { 'text-start': lang.direction == 'rtl' } ],attrs:{"dir":lang.direction}},[_c('v-col',{attrs:{"cols":"6","sm":"5"}},[_c('v-text-field',{class:[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' } ],attrs:{"prepend-icon":add.err.name && _vm.tabs == 0 ? 'error_outline' : '',"hide-details":"","error":_vm.tabs == 0 ? add.err.name : null,"value":add.name[lang.locale]},on:{"blur":function($event){return _vm.input_text(i, lang.locale, $event.target.value)}}},[_c('template',{slot:"label"},[(_vm.tabs == 0)?_c('strong',{staticClass:"error--text"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.translations.field_label)+" ")])],2)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabs == 0),expression:"tabs == 0"}],attrs:{"cols":"6","sm":"3"}},[_c('v-select',{class:[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' } ],attrs:{"item-text":"text","item-value":"related_to","value":add.related_to,"items":_vm.dialog_additions.is_public
                      ? _vm.translations.quan_public.items
                      : _vm.translations.quan_privet.items,"prepend-icon":add.err.related_to && _vm.tabs == 0 ? 'error_outline' : '',"hide-details":"","error":add.err.related_to},model:{value:(add.related_to),callback:function ($$v) {_vm.$set(add, "related_to", $$v)},expression:"add.related_to"}},[_c('template',{slot:"label"},[_c('strong',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.translations.quan_public.label)+" ")])],2)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabs == 0),expression:"tabs == 0"}],attrs:{"cols":"6","sm":"3"}},[_c('v-select',{class:[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' } ],attrs:{"items":_vm.translations.total.items,"item-text":"text","item-value":"total_to_table","value":add.total_to_table,"hide-details":"","error":add.err.total_to_table,"prepend-icon":add.err.total_to_table && _vm.tabs == 0 ? 'error_outline' : ''},model:{value:(add.total_to_table),callback:function ($$v) {_vm.$set(add, "total_to_table", $$v)},expression:"add.total_to_table"}},[_c('template',{slot:"label"},[_c('strong',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.translations.total.label)+" ")])],2)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabs == 0),expression:"tabs == 0"}],staticClass:"text-end",attrs:{"cols":"6","sm":"1"}},[_c('v-btn',{attrs:{"small":"","depressed":"","color":"error"},on:{"click":function($event){return _vm.remove_additon(i, add.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("close")])],1)],1)],1)}),1)}),1),_c('v-btn',{staticClass:"mt-5 mt-sm-3",attrs:{"outlined":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.new_addition()}}},[_vm._v(" "+_vm._s(_vm.translations.new_btn))])],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.close()}}},[_vm._v("الغاء")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v("حفظ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }