<template>
  <div>
    <v-card class="mb-3" elevation="0" outlined>
      <v-card-title>
        {{ translations.title }}
      </v-card-title>
      <!--/card title -->
      <v-card-text>
        <v-alert
          class="text-center"
          v-if="err.prop"
          dense
          border="left"
          type="error"
        >
          {{ err.text }}
        </v-alert>
        <v-tabs class="mb-3" v-model="tabs" background-color="gray">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            v-model="tabs"
            v-for="(lang, i) in this.$store.getters.languages"
            :key="i"
          >
            {{ lang.native }}
          </v-tab>
        </v-tabs>
        <!--/tabs -->

        <v-tabs-items v-model="tabs">
          <v-tab-item
            v-for="(lang, i) in this.$store.getters.languages"
            :key="i"
          >
            <v-row
              v-for="(f, i) in fields.fields"
              :key="i"
              :dir="lang.direction"
              :class="[
                { 'text-end': lang.direction == 'ltr' },
                { 'text-start': lang.direction == 'rtl' },
              ]"
              class="align-end pb-2 me-1"
            >
              <v-col cols="6" sm="5">
                <v-text-field
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  hide-details
                  :value="f.description[lang.locale]"
                  @input="
                    $emit('field_desc', {
                      prop_name: 'description',
                      section: 'fields',
                      value: $event,
                      locale: lang.locale,
                      ind: i,
                    })
                  "
                  :error="tabs == 0 ? fields.fields[i].err.desc : null"
                >
                  <template slot="label">
                    <strong v-if="tabs == 0" class="error--text">*</strong>
                    {{ translations.field }}
                  </template>
                </v-text-field>
              </v-col>
              <!--/ col -->
              <v-col cols="6" sm="3" v-show="tabs == 0">
                <v-select
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  hide-details
                  :items="translations.field_type.items"
                  item-text="text"
                  item-value="type"
                  :value="f.type"
                  @change="
                    $emit('filed_type', {
                      prop_name: 'type',
                      section: 'fields',
                      value: $event,
                      ind: i,
                    })
                  "
                  :error="f.err.type"
                >
                  <template slot="label">
                    <strong class="error--text">*</strong>
                    {{ translations.field_type.label }}
                  </template>
                </v-select>
              </v-col>
              <!--/ col -->
              <v-col cols="6" sm="3" v-show="tabs == 0">
                <v-select
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  hide-details
                  :items="translations.field_status.items"
                  item-text="text"
                  item-value="is_required"
                  :error="f.err.is_req"
                  :value="f.is_required"
                  @change="
                    $emit('field_is_required', {
                      prop_name: 'is_required',
                      section: 'fields',
                      value: $event,
                      ind: i,
                    })
                  "
                >
                  <template slot="label">
                    <strong class="error--text">*</strong>
                    {{ translations.field_status.label }}
                  </template>
                </v-select>
              </v-col>
              <!--/ col -->
              <v-col cols="6" sm="1" v-show="tabs == 0" class="text-end">
                <v-btn
                  @click="
                    $emit('remove_field', {
                      section: 'fields',
                      indx: i,
                      id: f.id,
                    })
                  "
                  small
                  depressed
                  color="error"
                >
                  <v-icon small>close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!--/ row -->
          </v-tab-item>
        </v-tabs-items>

        <v-btn
          outlined
          depressed
          class="mt-5 mt-md-3"
          color="primary"
          @click="$emit('new_field', { section: 'fields' })"
          >{{ translations.new_btn }}</v-btn
        >
      </v-card-text>
      <!--/ card text -->
    </v-card>
  </div>
</template>

<script>
export default {
  name: "fields",
  props: ["translations", "fields"],
  data() {
    return {
      tabs: 0,
      field: {
        err: {
          desc: false,
          type: false,
          is_req: false,
        },
        description: {},
        type: "",
        is_required: null,
      },
      err: {
        text: "برجاء ملئ المطلوب",
        prop: false,
      },
    };
  },
  methods: {
    vaild(e) {
      this.err.prop = e;
      if (e) {
        this.tabs = 0;
      }

    },
  },
};
</script>
