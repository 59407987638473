<template>
  <div class="login">
    <v-app-bar fixed flat color="transparent">
      <v-container fluid class="py-0 fill-height">
        <v-btn fab depressed @click="toggleDarkMode()">
          <v-icon> dark_mode </v-icon>
        </v-btn>
        <v-spacer />
        <router-link to="/">
          <img src="../assets/logo.png" width="110" class="mt-1 me-3" />
        </router-link>
      </v-container>
    </v-app-bar>
    <!--/end app-bar -->

    <div class="login__wrapper text-center">
      <v-row no-gutters>
        <v-col cols="6" class="d-none d-lg-block">
          <div class="login__bg">
            <LottieAnimation
              ref="anim"
              :animationData="require('@/assets/login/bg.json')"
              :loop="true"
              style="width: 100%"
            ></LottieAnimation>
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="login__colForm d-flex align-center justify-center"
        >
          <div class="login__form">
            <h1 class="login__h1 text-h2 font-weight-bold">
              مرحبًا ، من الجيد رؤيتك
            </h1>
            <div class="login__wrap">
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail *"
                  required
                  class="mb-10 font-weight-bold"
                  :disabled="checking"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  label="Password *"
                  type="password"
                  required
                  class="mb-5 font-weight-bold"
                  :disabled="checking"
                ></v-text-field>
                <div class="text-start">
                  <router-link
                    to="/forgetPassword"
                    class="text-decoration-underline black--text"
                    dark
                    >Forget password ?</router-link
                  >
                </div>

                <div class="d-flex justify-space-between mt-10">
                  <v-btn
                    to="/"
                    rounded
                    outlined
                    color="primary"
                    x-large
                    class="login__btn"
                    id="google"
                  >
                    حساب جوجل
                    <v-img
                      max-height="30"
                      max-width="30"
                      class="ms-2"
                      src="https://cdn.greeninvoice.co.il/auth-app/assets/1.0.152/img/google.51da111.svg"
                    ></v-img>
                  </v-btn>

                  <v-btn
                    :disabled="!valid"
                    :loading="checking"
                    rounded
                    color="success"
                    x-large
                    @click="validate"
                    class="login__btn"
                  >
                    دخول
                  </v-btn>
                </div>
              </v-form>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-snackbar width="auto" right v-model="snackbar.prop">
      {{ snackbar.err ? snackbar.errText : snackbar.succText }}
      <template v-slot:action="{ attrs }">
        <v-icon
          :color="snackbar.err ? snackbar.errColor : snackbar.succColor"
          v-bind="attrs"
          >{{ snackbar.err ? "highlight_off" : "done" }}</v-icon
        >
      </template>
    </v-snackbar>
  </div>
</template>

<style lang="scss" scoped>
.v-btn--fab.v-size--default {
  width: 40px;
  height: 40px;
}
.login {
  &__wrapper {
    min-height: 100vh;
  }
  &__colForm {
    height: 100vh;
  }
  &__bg {
    background: #cfdeffba;
    width: 100%;
    height: 100%;
    padding-top: 100px;
  }
  &__form {
    padding: 120px 25px;
  }
  form {
    width: 100%;
  }
  &__h1 {
    margin-bottom: 78px;
    &.text-h2 {
      @media (max-width: 767px) {
        margin-bottom: 30px;
        font-size: 30px !important;
      }
    }
  }
  &__btn.v-size--x-large {
    width: calc(100% - 220px);
    padding: 30px 40px;
    height: 60px !important;
    line-height: 85px;
    font-size: 18px;
    font-weight: bold;
    &#google {
      width: 200px;
      &:hover {
        &:before {
          background-color: #55c0b4 !important;
          opacity: 1;
        }

        color: #fff !important;
      }
    }
  }
}
.theme--dark {
  .login__bg {
    filter: grayscale(1);
  }
}
</style>
<script>
import LottieAnimation from "lottie-web-vue";
import static_trans from "../mixins/static_trans";

export default {
  name: "Login",
  data: () => ({
    checking: false,
    trans:{
      categories:{
      }
    },
    snackbar: {
      prop: false,
      err: true,
      errColor: "red lighteen-2",
      succColor: "primary",
      errText: "Username or password not valid",
      succText: "Login success",
    },
    valid: true,
    email: "admin@admin.com ",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    password: "password",
    passwordRules: [(v) => !!v || "Password is required"],
  }),
  components: {
    LottieAnimation,
  },
  mixins: [static_trans],
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.checking = true;
        this.$store
          .dispatch("login", {
            email: this.email,
            password: this.password,
          })
          .then((res) => {
            this.snackbar.prop = true;
            this.snackbar.err = false;
            setTimeout(() => {
              this.$store.dispatch("languages");
              this.$router.push({ name: "compaines_page" });
            }, 1000);
          })
          .catch((err) => {
            this.snackbar.prop = true;
            this.snackbar.err = true;
            this.checking = false;
          });
      }
    },
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>
