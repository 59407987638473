<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!$store.state.categories">
    </v-skeleton-loader>

    <v-card
      v-if="$store.state.categories"
      class="text-center"
      elevation="0"
      outlined
    >
      <v-card-title class="d-flex">
        <span class="order-1">{{ keys.categories_page_name }}</span>
        <v-spacer class="order-2"></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="keys.categories_page_search_input"
          single-line
          hide-details
          class="order-4 order-sm-3"
        ></v-text-field>
        <v-spacer class="order-4 d-none d-sm-block"></v-spacer>

        <!-- start dialog -->
        <v-dialog max-width="500" v-model="dialog">
          <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip bottom nudge-bottom="-5">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-bind="attrs"
                  v-on="{ ...dialog, ...tooltip }"
                  elevation="0"
                  class="order-3 order-sm-5"
                  color="primary"
                  >{{ keys.categories_page_btn_add }}
                </v-btn>
              </template>
              <span>{{ keys.categories_page_btn_add }}</span>
            </v-tooltip>
          </template>
          <!-- add -->
          <v-card elevation="0" outlined>
            <v-card-title class="font-weight-bold MB-">
              {{ dialogTitle }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-3">
              <v-alert
                class="text-center"
                v-if="err"
                dense
                border="left"
                type="error"
              >
                {{ errText }}
              </v-alert>
              <v-tabs class="mb-3" v-model="tabs" background-color="gray">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab
                  v-model="tabs"
                  v-for="(lang, i) in this.$store.getters.languages"
                  :key="i"
                >
                  {{ lang.native }}
                </v-tab>
              </v-tabs>
              <!--/tabs -->

              <v-tabs-items v-model="tabs">
                <v-tab-item
                  v-for="(lang, i) in this.$store.getters.languages"
                  :key="i"
                >
                  <v-row
                    :dir="lang.direction"
                    :class="[
                      { 'text-end': lang.direction == 'ltr' },
                      { 'text-start': lang.direction == 'rtl' },
                    ]"
                  >
                    <v-col cols="12">
                      <v-text-field
                        class="mt-1"
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        :error="i == 0 ? errName : null"
                        v-model="editItemObj.name[lang.locale]"
                        hide-details
                      >
                        <template slot="label">
                          <strong class="error--text">*</strong>
                          {{ keys.categories_page_cat_name }}
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="mb-1" cols="12">
                      <v-textarea
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        rows="3"
                        :error="i == 0 ? errDesc : null"
                        v-model="editItemObj.description[lang.locale]"
                        hide-details
                      >
                        <template slot="label">
                          <strong class="error--text">*</strong>
                          {{ keys.categories_popup_cat_desc }}
                        </template>
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
            <!--/ card text -->
            <v-card-actions>
              <v-btn
                color="primary"
                small
                class="mt-3 mb-5 font-weight-bold"
                text
                :loading="load"
                :disabled="load"
                @click="save()"
                >{{ keys.categories_popup_btn_save }}</v-btn
              >
              <v-btn
                @click="close()"
                color="red"
                small
                class="mt-3 mb-5 font-weight-bold"
                text
                >{{ keys.categories_popup_btn_close }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--/dialog add & edit -->
      </v-card-title>

      <!--/ start table -->
      <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        sort-by="calories"
        no-data-text="لا يوجد نتائج "
        no-results-text="لا يوجد نتائج بحث"
        :footer-props="$store.state.dataTableFooterProps"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="editCategory(item)" fab small icon>
            <v-icon small>edit</v-icon>
          </v-btn>
          <v-btn @click="getDeleteId(item.id)" fab small icon>
            <v-icon small>delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <!--/ end  table -->
    </v-card>

    <v-dialog max-width="300" v-model="deleteDialog">
      <v-card>
        <v-card-title class="text-center d-block"
          >{{ keys.categories_delete_popup_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="loadDelete"
            @click="deleteCategory()"
            depressed
            color="error"
            >{{ keys.categories_delete_popup_delete_btn }}</v-btn
          >
          <v-btn @click="deleteDialog = false" depressed>{{
            keys.categories_delete_popup_close_btn
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
</template>

<script>
export default {
  name: "categories",
  data() {
    return {
      tabs: null,
      //start  props for delete category
      deleteDialog: false,
      loadDelete: false,
      deleteId: null,
      //End **************

      langsArr: [],
      trans: [
        {
          tag: "",
          key: "page_categories_title",
          value: "asdasd",
        },
        {
          key: "page_categories_title",
          value: "asdasd",
        },
        {
          key: "page_categories_title",
          value: "asdasd",
        },
      ],

      load: false,
      categoriesDialgo: {
        modal: false,
        names: [],
      }, // for view sent to
      menu: false,

      items: [
        { id: 1, name: "mohaned" },
        { id: 2, name: "ahmed" },
        { id: 3, name: "kahled" },
        { id: 4, name: "saed" },
        { id: 5, name: "mustafa" },
        { id: 6, name: "taher" },
      ],

      //  Add and edit category
      errText: "",
      err: false,
      errName: false,
      errDesc: false,
      dialog: false,
      indexItem: -1,
      data: {
        created_at: "",
        id: null,
        description: "",
      },

      editItemObj: {
        description: {},
        name: {},
      },
      defualtItem: {
        name: {},
        description: {},
      },
      search: "", // table search prop
    };
  },
  watch: {
    dialog(newvalue, oldvalue) {
      if (!newvalue && oldvalue) this.close();
    },
  },
  computed: {
    dialogTitle() {
      return this.indexItem == -1
        ? this.keys.categories_popup_add_title
        : this.keys.categories_popup_edit_title;
    },
    desserts() {
      return this.$store.state.categories || []; // get all categories from vuex store
    },
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    headers() {
      return [
        {
          text: this.keys.categories_page_cat_num,
          value: "id",
          align: "center",
        },
        {
          text: this.keys.categories_page_cat_name,
          value: "name_locale",
          align: "center",
        },
        {
          text: this.keys.categories_page_cat_creation_date,
          value: "created_at",
          align: "center",
        },
        {
          text: this.keys.categories_page_cat_actions,
          value: "actions",
          sortable: false,
          align: "center",
        },
      ];
    },
  },

  methods: {
    dialogDate(cats) {
      this.categoriesDialgo.modal = true;
      this.categoriesDialgo.names = cats;
    },
    // fade the dialog then geting the category   item which want to edit  then assign for editITemObj
    editCategory(item) {
      this.indexItem = this.desserts.indexOf(item);
      this.editItemObj = Object.assign({}, item);
      this.dialog = true;
    },
    // make validation before request edit or create if name empty || desc empty
    valid() {
      let default_lang_locale = this.$store.getters.languages.find(
        (e) => e.is_default
      ).locale;
      if (!this.editItemObj.name[default_lang_locale]) {
        this.err = true;
        this.errName = true;
        this.tabs = 0;
        this.errText = this.keys.categories_page_name_empty_err;
        return false;
      } else {
        this.err = false;
        this.errName = false;
      }
      if (!this.editItemObj.description[default_lang_locale]) {
        this.err = true;
        this.errDesc = true;
        this.errText = "Please type the description in default language";
        this.tabs = 0;
        return false;
      } else {
        this.err = false;
        this.errDesc = false;
      }
      return true;
    },
    // save the edit or create
    save() {
      if (this.valid()) {
        // check if  everything is valid
        this.load = true; // make save button loading
        if (this.indexItem > -1) {
          //  this condetion  to check if user want to edit or create
          let data = JSON.stringify(this.editItemObj);
          let config = {
            method: "put",
            url: `categories/${this.editItemObj.id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${this.$store.state.token}`,
              "Content-Type": "application/json",
            },
            data: data,
          };
          this.axios(config)
            .then((res) => {
              this.$store.dispatch("categories");
              this.close();
            })
            .catch((err) => {
              this.err = true;
              this.errText = this.keys.categories_page_name_duplicate_err;
              this.load = false;
            });
        } else {
          let data = JSON.stringify(this.editItemObj);
          let config = {
            method: "post",
            url: "categories",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${this.$store.state.token}`,
              "Content-Type": "application/json",
            },
            data: data,
          };
          this.axios(config)
            .then((res) => {
              this.$store.dispatch("categories");
              this.close();
            })
            .catch((err) => {
              this.err = true;
              this.errText = this.keys.categories_page_name_duplicate_err;
              this.load = false;
            });
        }
      }
    },
    close() {
      // close the edit & create window after  saveing or closeing
      this.load = false; // remove save loading
      this.dialog = false; // close window
      this.errName = false;
      this.errDesc = false;
      this.err = false;
      this.tabs = 0;
      this.editedIndex = -1;
      this.$nextTick(() => {
        Object.keys(this.editItemObj).forEach((e) => {
          this.editItemObj[e] = {};
        }); //
      });
    },
    // get delete id then assign for prop to use it
    getDeleteId(id) {
      this.deleteId = id;
      this.deleteDialog = true;
    },
    // delete category
    deleteCategory() {
      this.loadDelete = true; // make delete button start loading
      let config = {
        method: "delete",
        url: `categories/${this.deleteId}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.$store.state.token}`,
        },
      };
      this.axios(config).then((res) => {
        let findindex = this.desserts.findIndex((e) => e.id == this.deleteId); // get index of  category delete for delete form desserts
        this.desserts.splice(findindex, 1);
        this.deleteDialog = false;
        this.loadDelete = false;
      });
    },
  },
  mounted() {
    let cats = this.$store.state.categories;
    if (!cats) {
      this.$store.dispatch("categories");
    }

    let vm = this;
    window.onkeyup = function (e) {
      // make delete action in enter key
      if (e.key == "Enter") {
        vm.deleteCategory();
      }
    };
  },
};
</script>
