<template>
  <div>
    <v-navigation-drawer
      color="secondary"
      class="app-menu"
      v-model="$store.state.drawer"
      app
      :right="rtl"
    >
      <v-list nav dense class="pt-10 pb-10 ps-10 pe-10">
        <v-list-item class="white--text d-none" to="/" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">home</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">Home</v-list-item-title>
        </v-list-item>
        <v-list-item class="white--text" to="/Companies" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">business</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">Companies</v-list-item-title>
        </v-list-item>
        <v-list-item class="white--text d-none" to="/Employees" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">groups</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">Employees</v-list-item-title>
        </v-list-item>
        <v-list-item class="white--text" to="/departments" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">departments</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/packages" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">trending_up</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">packages</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text d-none" to="/Payments" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">payment</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">Payments</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/projectTypes" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">cases</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">Project Types</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/categories" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">category</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">categories</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/languages" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">g_translate</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">languages</v-list-item-title>
        </v-list-item>
        <v-list-item class="white--text" to="/prices" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">g_translate</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">prices</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text d-none" to="/messages" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">move_to_inbox</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">Messages</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text d-none" to="/data" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">description</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">data</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text d-none" to="/employeesPermissions" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">engineering</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">Permissions</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .app-menu {
    .v-navigation-drawer__content {
      .list {
        padding-bottom: 100px !important;
      }
      &::-webkit-scrollbar {
        width: 0;
        scrollbar-width: none;
      }
    }
  }
}
</style>
<script>
export default {
  name: "AsideMenu",
  data() {
    return {};
  },
  computed: {
    rtl() {
      return this.$vuetify.rtl;
    },
  },
};
</script>

<style></style>
