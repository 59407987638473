<template>
  <v-container>
    <section class="mb-10">

      <v-row>
        <v-col
          lg="3"
          md="6"
          cols="6"
          class="mb-4"
          v-for="(a, v) in analayze"
          :key="v"
        >
          <v-card elevation="0" outlined class="analayze rounded-lg">
            <v-card-text>
              <div class="d-flex justify-space-between mb-5">
                <h2 class="h3 analayze__title" :style="{ color: a.color }">
                  <span class="analayze__currency">₪</span>{{ a.money }}
                </h2>
                <v-icon class="analayze__icon" :style="{ color: a.color }">
                  circle</v-icon
                >
              </div>
              <div class="d-flex justify-space-between">
                <span class="analayze__percent">{{ a.percent }}%</span>
                <h2 class="analayze__title analayze__title--text">
                  {{ a.text }}
                </h2>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row>
        <v-col cols="6">
          <v-card class="mx-auto text-center" color="green">
            <v-card-text>
              <v-sheet color="rgba(0, 0, 0, .12)">
                <v-sparkline
                  :value="value"
                  color="rgba(255, 255, 255, .7)"
                  height="100"
                  padding="24"
                  stroke-linecap="round"
                  smooth
                >
                  <template v-slot:label="item"> ${{ item.value }} </template>
                </v-sparkline>
              </v-sheet>
            </v-card-text>

            <v-card-text>
              <div class="text-h4 font-weight-thin">Sales Last 24h</div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="justify-center">
              <v-btn block text> Go to Report </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card class="mt-4 mx-auto">
            <v-sheet
              class="v-sheet--offset mx-auto"
              color="cyan"
              elevation="12"
              max-width="calc(100% - 32px)"
            >
              <v-sparkline
                :labels="labels"
                :value="value"
                color="white"
                line-width="2"
                padding="16"
              ></v-sparkline>
            </v-sheet>

            <v-card-text class="pt-0">
              <div class="text-h6 font-weight-light mb-2">
                User Registrations
              </div>
              <div class="subheading font-weight-light grey--text">
                Last Campaign Performance
              </div>
              <v-divider class="my-2"></v-divider>
              <v-icon class="mr-2" small> mdi-clock </v-icon>
              <span class="text-caption grey--text font-weight-light"
                >last registration 26 minutes ago</span
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
export default {
  name: "SuperDashboard",
  data() {
    return {
      labels: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
      value: [200, 675, 410, 390, 310, 460, 250, 240],
      value2: [423, 446, 675, 510, 590, 610, 760],
      analayze: [
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#00ffe7",
          money: 77,
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#ffa500",

          money: 77,
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#188320",
          money: 77,
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "red",
          money: 77,
        },
      ],
    };
  },
};
</script>
