<template>
  <v-container>
    <v-card class="text-center" elevation="0" outlined>
      <v-card-title class="d-flex">
        صلاحيات الموظفين
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip bottom nudge-bottom="-5">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-bind="attrs"
                  v-on="{ ...dialog, ...tooltip }"
                  elevation="0"
                  color="primary"
                  >اضافة صلاحية
                </v-btn>
              </template>
              <span>اضافة</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ dialogTitle }}</span>
            </v-card-title>
            <!--/title -->
            <v-divider></v-divider>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label=" نوع الموظف"
                    v-model="editItemObj.phone"
                    hide-details
                  ></v-text-field>
                </v-col>
                <!--/col -->
                <v-col cols="12" md="6">
                  <v-text-field
                    label=" وصف"
                    v-model="editItemObj.whatsapp"
                    hide-details
                  ></v-text-field>
                </v-col>
                <!--/col -->
                <v-col cols="12">
                  <v-select
                    :items="items"
                    chips
                    label="الصلاحيات"
                    multiple
                    v-model="editItemObj.permations"
                    item-text="name"
                    item-value="id"
                    hide-details
                  ></v-select>
                </v-col>
                <!--/col -->
              </v-row>
              <!--/row -->
            </v-card-text>
            <!--/card text -->

            <v-card-actions>
              <v-btn @click="close()" small class="font-weight-bold" color="red" text>
                الغاء
              </v-btn>
              <v-btn
                @click="save(), (dialog = false)"
                color="primary"
                class="font-weight-bold"
                text
                small
              >
                حفظ
              </v-btn>
            </v-card-actions>
          </v-card>
          <!--/card -->
        </v-dialog>
        <!--/dialog -->
      </v-card-title>
      <!--/title -->
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :items="desserts"
        item-key="id"
        sort-by="calories"
        hide-default-footer
        :mobile-breakpoint="0"
      >
        <template v-slot:[`item.employeesCount`]="{ item }">
          <v-chip
            color="red accent-1 white--text"
            x-small
            v-if="item.employeesCount == 0"
          >
            لايوجد
          </v-chip>
          <span v-if="item.employeesCount > 0">
            {{ item.employeesCount }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn fab icon small @click="editItem(item)">
            <v-icon small> edit </v-icon>
          </v-btn>
          <v-btn fab icon small>
            <v-icon small> delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <!--/ data table -->
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "employeesPermissions",
  data() {
    return {
      items: [
        { id: 1, name: "Item 1", description: "Item 1 description." },
        { id: 2, name: "Item 2", description: "Item 2 description." },
        { id: 3, name: "Item 3", description: "Item 3 description." },
        { id: 4, name: "Item 4", description: "Item 4 description." },
        { id: 5, name: "Item 1", description: "Item 1 description." },
        { id: 6, name: "Item 2", description: "Item 2 description." },
        { id: 7, name: "Item 3", description: "Item 3 description." },
        { id: 8, name: "Item 4", description: "Item 4 description." },
      ],
      form: {
        id: null,
        name: "",
        description: "",
      },
      plans: ["plan1", "plan2"],
      closeOnContentClick: true,
      closeOnContentClick2: true,
      dialog: false,
      search: "",
      dialog: false,
      today: new Date(),
      editItemObj: {
        name: "",
        employeeType: "",
        phone: "",
        whatsapp: "",
        passowrd: "",
        permations: [],
      },
      defualtItem: {
        name: "",
        employeeType: "",
        phone: "",
        whatsapp: "",
        passowrd: "",
        permations: [],
      },
      indexItem: -1,
      headers: [
        { text: "النوع", value: "name", align: "center" },
        { text: "عدد الموظفين", value: "employeesCount", align: "center" },
        { text: "وصف", value: "desc", align: "center" },
        { text: "اجراءات", value: "actions", sortable: false, align: "center" },
      ],
      desserts: [
        {
          name: "موظف عادي",
          employeesCount: 0,
          desc: "وصف",
        },
      ],
    };
  },
  computed: {
    dialogTitle() {
      return this.indexItem == -1 ? "اضافة صلاحية" : "تعديل صلاحية";
    },
  },
  methods: {
    editItem(item) {
      this.dialog = true;
      this.indexItem = this.desserts.indexOf(item);
      this.editItemObj = Object.assign({}, item);
    },
    save() {
      if (this.indexItem > -1) {
        Object.assign(this.desserts[this.indexItem], this.editItemObj);
      } else {
        this.desserts.push(this.editItemObj);
      }
      this.close();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editItemObj = Object.assign({}, this.defualtItem);
        this.indexItem = -1;
      });
    },
  },
};
</script>
