import Vue from "vue";
import App from "./App.vue";
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import DatetimePicker from 'vuetify-datetime-picker'




Vue.use(VueAxios, axios)



axios.defaults.baseURL = "https://app.kalko.cloud/api/v1/admin";
// axios.defaults.withCredentials = true;


// import the es6 version
import 'zingchart/es6';
// import the component AFTER ZingChart since it is a DIRECT dependency
import zingchartVue from 'zingchart-vue';

Vue.component('zingchart', zingchartVue);
Vue.use(DatetimePicker)
Vue.config.productionTip = false;



import "@/styles/main.scss";
new Vue({
  router,
  store,
  vuetify,

  render: (h) => h(App),
}).$mount("#app");
