var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.keys.new_package_page_name)+" ")]),_c('v-divider'),_c('v-card-text',[_c('v-tabs',{staticClass:"mb-3",attrs:{"background-color":"gray"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((this.$store.getters.languages),function(lang,i){return _c('v-tab',{key:i,model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_vm._v(" "+_vm._s(lang.native)+" ")])})],2),_c('form',{ref:"form"},[_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((this.$store.getters.languages),function(lang,i){return _c('v-tab-item',{key:i},[_c('v-row',{staticClass:"align-start",class:[
                { 'text-end': lang.direction == 'ltr' },
                { 'text-start': lang.direction == 'rtl' } ],attrs:{"dir":lang.direction}},[_c('v-col',{staticClass:"mb-1",attrs:{"md":"4","cols":"12"}},[_c('v-text-field',{class:[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' } ],attrs:{"hide-details":"","required":"","error":_vm.error},model:{value:(_vm.data.name[lang.locale]),callback:function ($$v) {_vm.$set(_vm.data.name, lang.locale, $$v)},expression:"data.name[lang.locale]"}},[_c('template',{slot:"label"},[_c('strong',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.keys.new_package_page_package_name)+" ")])],2)],1),(_vm.tabs == 0 )?_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-text-field',{class:[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' } ],attrs:{"hide-details":"","type":"number","min":"0"},model:{value:(_vm.data.month_price),callback:function ($$v) {_vm.$set(_vm.data, "month_price", _vm._n($$v))},expression:"data.month_price"}},[_c('template',{slot:"label"},[_c('strong',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.keys.new_package_page_package_month_price)+" ")])],2)],1):_vm._e(),(_vm.tabs == 0 )?_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-text-field',{class:[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' } ],attrs:{"type":"number","hide-details":"","label":"السعر السنوي","min":"0"},model:{value:(_vm.data.annual_price),callback:function ($$v) {_vm.$set(_vm.data, "annual_price", _vm._n($$v))},expression:"data.annual_price"}},[_c('template',{slot:"label"},[_c('strong',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.keys.new_package_page_package_annual_price)+" ")])],2)],1):_vm._e(),(_vm.tabs == 0 )?_c('v-col',{attrs:{"cols":"9"}},[_c('v-autocomplete',{class:[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' } ],attrs:{"items":_vm.items,"chips":"","item-text":"name","item-value":"id","multiple":"","return-object":""},on:{"change":_vm.changed},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
                  var attrs = ref.attrs;
return [_c('v-chip',_vm._b({attrs:{"close":""},on:{"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(item){return [_c('v-list-item-content',{on:{"click":function($event){return _vm.selectedItem(item)}}},[_c('v-checkbox',{staticClass:"ma-0",attrs:{"hide-details":"","label":item.item.name},model:{value:(item.item.selected),callback:function ($$v) {_vm.$set(item.item, "selected", $$v)},expression:"item.item.selected"}})],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"label"},[_c('strong',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.keys.new_package_page_package_recipients)+" ")])],2)],1):_vm._e(),(_vm.tabs == 0 )?_c('v-col',{attrs:{"cols":"3"}},[_c('v-switch',{attrs:{"label":((_vm.keys.new_package_page_package_status) + " (" + (_vm.data.is_active
                      ? _vm.keys.new_package_page_package_status_active
                      : _vm.keys.new_package_page_package_status_not_active) + ")")},model:{value:(_vm.data.is_active),callback:function ($$v) {_vm.$set(_vm.data, "is_active", $$v)},expression:"data.is_active"}})],1):_vm._e()],1)],1)}),1)],1)],1)],1),_c('v-btn',{staticClass:"mt-3",attrs:{"loading":_vm.loading,"large":"","color":"primary","depressed":""},on:{"click":function($event){return _vm.sendPackage()}}},[_vm._v(" "+_vm._s(_vm.keys.new_package_page_package_save_btn)+" ")]),_c('v-snackbar',{attrs:{"width":"auto","right":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),[_vm._v("check_circle")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" تم اضافة الخطة بنجاح ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }