<template>
  <div>
    
    <v-card class="mb-3" elevation="0" outlined>
      <v-card-title>
        {{ translations.title }}
        <v-spacer></v-spacer>
        <v-btn @click="new_group()" color="primary" outlined
          >اضافة مجموعة</v-btn
        >
      </v-card-title>
      <!--/card title -->
      <v-card-text>
        <v-list>
          <v-list-item v-for="(addition, i) in additions.additions" :key="i">
            <!--/ action -->
            <v-list-item-content>
              <span
                ><v-icon v-if="addition.is_public" small>lock</v-icon>
                {{
                  !addition.is_public ? addition.name[default_lang_locale] : "......."
                }}
                
              </span></v-list-item-content
            >
            <!--/ item content -->
            <v-list-item-action>
              <div class="d-flex px-2">
                <v-btn max-width="28px" @click="edit_additions(i, addition)" small icon>
                  <v-icon small>edit</v-icon>
                </v-btn>
                <v-btn
                  @click="
                    $emit('remove_group_addition', {
                      id: addition.id,
                      addition_index: i,
                    })
                  "
                  v-if="!addition.is_public"
                  small
                  icon
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <!--/ card text -->
    </v-card>

    <v-dialog scrollable persistent v-model="dialog" max-width="800">
      <v-card>
        <!-- <pre>
          {{dialog_additions}}
        </pre> -->
        <v-card-title>Group name</v-card-title>
        <v-divider></v-divider>
        <v-card-text class='mt-3'>
          <v-alert
            class="text-center"
            v-if="err.prop"
            dense
            border="left"
            type="error"
          >
            {{ err.text }}
          </v-alert>

          <v-tabs
            v-if="!dialog_additions.is_public"
            class="mb-3"
            v-model="grouptabs"
            background-color="gray"
          >
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab
              v-model="grouptabs"
              v-for="(lang, i) in this.$store.getters.languages"
              :key="i"
            >
              {{ lang.native }}
            </v-tab>
          </v-tabs>
          <!--/tabs -->
          <h3 class='my-3 black--text'  v-if="dialog_additions.is_public">{{ translations.group_title }}</h3>
          <v-tabs-items v-if="!dialog_additions.is_public" v-model="grouptabs">
            <v-tab-item
              v-for="(lang, i) in this.$store.getters.languages"
              :key="i"
            >
              <v-row
                :dir="lang.direction"
                :class="[
                  { 'text-end': lang.direction == 'ltr' },
                  { 'text-start': lang.direction == 'rtl' },
                ]"
                class="align-end pb-2 me-1 me-md-0"
              >
                <v-col cols="6" sm="5">
                  <v-text-field
                    :class="[
                      { 'right-label': lang.direction == 'rtl' },
                      { 'left-label': lang.direction == 'ltr' },
                    ]"
                    :prepend-icon="
                      dialog_additions.err_group_name && grouptabs == 0
                        ? 'error_outline'
                        : ''
                    "
                    hide-details
                    :error="
                      grouptabs == 0 ? dialog_additions.err_group_name : null
                    "
                    :value="
                      Object.keys(dialog_additions).length != 0
                        ? dialog_additions.name[lang.locale]
                        : ''
                    "
                    @blur="
                      (ev) =>
                        $set(
                          dialog_additions.name,
                          lang.locale,
                          ev.target.value
                        )
                    "
                  >
                    <template slot="label">
                      <strong v-if="grouptabs == 0" class="error--text"
                        >*</strong
                      >
                      name group
                    </template>
                  </v-text-field>
                </v-col>
                <!--/ col -->
              </v-row>
            </v-tab-item>
          </v-tabs-items>

          <v-tabs class="mb-3" v-model="tabs" background-color="gray">
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab
              v-model="tabs"
              v-for="(lang, i) in this.$store.getters.languages"
              :key="i"
            >
              {{ lang.native }}
            </v-tab>
          </v-tabs>
          <!--/tabs -->

          <v-tabs-items v-model="tabs">
            <v-tab-item
              v-for="(lang, i) in this.$store.getters.languages"
              :key="i"
            >
              <v-row
                v-for="(add, i) in dialog_additions.children"
                :key="i"
                :dir="lang.direction"
                :class="[
                  { 'text-end': lang.direction == 'ltr' },
                  { 'text-start': lang.direction == 'rtl' },
                ]"
                class="align-end pb-2 me-1 me-md-0"
              >
                <v-col cols="6" sm="5">
                  <v-text-field
                    :class="[
                      { 'right-label': lang.direction == 'rtl' },
                      { 'left-label': lang.direction == 'ltr' },
                    ]"
                    :prepend-icon="
                      add.err.name && tabs == 0 ? 'error_outline' : ''
                    "
                    @blur="input_text(i, lang.locale, $event.target.value)"
                    hide-details
                    :error="tabs == 0 ? add.err.name : null"
                    :value="add.name[lang.locale]"
                  >
                    <template slot="label">
                      <strong v-if="tabs == 0" class="error--text">*</strong>
                      {{ translations.field_label }}
                    </template>
                  </v-text-field>
                </v-col>
                <!--/ col -->
                <v-col cols="6" sm="3" v-show="tabs == 0">
                  <v-select
                    item-text="text"
                    item-value="related_to"
                    :value="add.related_to"
                    :items="
                      dialog_additions.is_public
                        ? translations.quan_public.items
                        : translations.quan_privet.items
                    "
                    :prepend-icon="
                      add.err.related_to && tabs == 0 ? 'error_outline' : ''
                    "
                    hide-details
                    v-model="add.related_to"
                    :error="add.err.related_to"
                    :class="[
                      { 'right-label': lang.direction == 'rtl' },
                      { 'left-label': lang.direction == 'ltr' },
                    ]"
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ translations.quan_public.label }}
                    </template>
                  </v-select>
                </v-col>
                <!--/ col -->
                <v-col cols="6" sm="3" v-show="tabs == 0">
                  <v-select
                    :items="translations.total.items"
                    item-text="text"
                    item-value="total_to_table"
                    :value="add.total_to_table"
                    hide-details
                    :error="add.err.total_to_table"
                    v-model="add.total_to_table"
                    :prepend-icon="
                      add.err.total_to_table && tabs == 0 ? 'error_outline' : ''
                    "
                    :class="[
                      { 'right-label': lang.direction == 'rtl' },
                      { 'left-label': lang.direction == 'ltr' },
                    ]"
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ translations.total.label }}
                    </template>
                  </v-select>
                </v-col>
                <!--/ col -->
                <v-col cols="6" sm="1" v-show="tabs == 0" class="text-end">
                  <v-btn
                    @click="remove_additon(i, add.id)"
                    small
                    depressed
                    color="error"
                  >
                    <v-icon small>close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <!--/ row -->
            </v-tab-item>
          </v-tabs-items>

          <v-btn
            @click="new_addition()"
            outlined
            depressed
            class="mt-5 mt-sm-3"
            color="primary"
          >
            {{ translations.new_btn }}</v-btn
          >
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="close()" text color="error">الغاء</v-btn>
          <v-btn @click="save()" color="primary">حفظ</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "additions",
  props: ["translations", "additions"],
  data() {
    return {
      delete_ids: [],
      tabs: 0,
      grouptabs: 0,
      addition_index: 0,
      is_public: true,
      dialog: false,
      dialog_additions: {},
      err: {
        text: "برجاء ملئ المطلوب",
        prop: false,
      },
    };
  },
  watch: {
    dialog(newvalue, oldvalue) {
      if (!newvalue && oldvalue) {
        this.addition_index = 0;
      }
    },
  },
  computed: {
    default_lang_locale() {
      return this.$store.getters.languages.find((e) => e.is_default).locale;
    },
  },
  methods: {
    edit_additions(additions_index, addition_obj) {
      this.dialog_additions = Object.assign({},JSON.parse(JSON.stringify(addition_obj)));
      this.dialog_additions.children.forEach((e, i) => {
        this.$set(this.dialog_additions.children[i], "err", {
          name: false,
          related_to: false,
          total_to_table: false,
        });
      });
      this.addition_index = additions_index;
      this.is_public = addition_obj.is_public;
      this.dialog = true;


      console.log(addition_obj)
    },
    vaild(e) {
      if (e) {
        this.tabs = 0;
      }

      this.err.prop = e;
    },

    new_group() {
      this.$emit("new_group");
      this.$nextTick((e) => {
        this.addition_index = this.additions.additions.length - 1;
        this.dialog_additions = Object.assign(
          {},
          JSON.parse(
            JSON.stringify(this.additions.additions[this.addition_index])
          )
        );
      });

      this.dialog = true;
    },
    input_text(ind, locale, value) {
      this.$set(this.dialog_additions.children[ind].name, locale, value);
    },
    new_addition() {
      let addition = {
        name: {},
        related_to: null,
        total_to_table: null,
        err: {
          name: false,
          related_to: false,
          total_to_table: false,
        },
      };
      let child_index = this.dialog_additions.children.length;
      this.$set(this.dialog_additions.children, child_index, addition);
    },
    remove_additon(ind, id) {
      this.dialog_additions.children.splice(ind, 1);
      if (id != undefined) {
        this.delete_ids.push(id);
      }
    },
    delete_addtions() {
      if (this.delete_ids.length != 0) {
        let config = {
          method: "delete",
          url: `prices/additions/${e.id}`,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${this.$store.state.token}`,
            "Content-Type": "application/json",
          },
        };
        this.axios(config);
      }
    },
    save() {
      let errors = [];

      if (this.dialog_additions.children.length == 0) {
        errors.push(1);
      }

      if (!this.dialog_additions.name[this.default_lang_locale] && !this.dialog_additions.is_public) {
        this.$set(this.dialog_additions, "err_group_name", true);
        errors.push(1);
      } else {
        this.$set(this.dialog_additions, "err_group_name", false);
      }
      this.dialog_additions.children.forEach((e, i) => {
        if (!e.name[this.default_lang_locale]) {
          this.$set(this.dialog_additions.children[i].err, "name", true);
          errors.push(i);
        } else {
          this.$set(this.dialog_additions.children[i].err, "name", false);
        }

        if (!e.related_to) {
          this.$set(this.dialog_additions.children[i].err, "related_to", true);
          errors.push(i);
        } else {
          this.$set(this.dialog_additions.children[i].err, "related_to", false);
        }
        if (e.total_to_table == null) {
          this.$set(
            this.dialog_additions.children[i].err,
            "total_to_table",
            true
          );
          errors.push(i);
        } else {
          this.$set(
            this.dialog_additions.children[i].err,
            "total_to_table",
            false
          );
        }
      });

      
      console.log(this.dialog_additions)
      if (errors.length == 0) {
        this.$emit("set_additons_group", {
          group: this.dialog_additions,
          group_index: this.addition_index,
        });
        this.$nextTick((e) => {
          this.close();
        });

        this.err.prop = false;
      } else {
        this.grouptabs = 0;
        this.tabs = 0;
        this.err.prop = true;
      }
    },
    close() {
      this.tabs = 0;
      this.grouptabs = 0;
      this.$set(this, "dialog_additions", {});
      this.dialog = false;
    },
  },
};
</script>
