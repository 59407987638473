<template>
  <v-container>
      <v-btn elevation="0"  to="/plans" class='mb-3 font-weight-bold'>
            <v-icon color="orange darken-1" class='me-1'>keyboard_backspace</v-icon> صفحة الرزم
      </v-btn>
    <v-card elevation="0" outlined class="mb-2">
      <v-card-title class="font-weight-bold align-end d-flex">
       <span class='font-weight-bold primary--text me-2'>(الذهبية)</span>    الشركات المتاحة في روزمة 
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="بحث"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        sort-by="calories"
        no-data-text="لا يوجد نتائج "
        no-results-text="لا يوجد نتائج بحث"
        :mobile-breakpoint="0"
      >
        <template v-slot:item[`actions`]="{ item }">
          <v-menu offset-y :close-on-content-click="closeOnContentClick">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                fab
                small
                v-bind="attrs"
                v-on="on"
                @click="closeOnContentClick = !closeOnContentClick"
              >
                <v-icon small>call</v-icon>
              </v-btn>
            </template>
            <v-list class="text-center">
              <v-list-item outlined href="tel:021231231" link>
                <v-list-item-title class="subtitle-2 font-weight-bold"
                  >اتصل الان</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="subtitle-2"
                  >02132123154</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu offset-y :close-on-content-click="closeOnContentClick2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                class="mx-2"
                icon
                v-bind="attrs"
                v-on="on"
                @click="closeOnContentClick = !closeOnContentClick2"
              >
                <v-icon small>whatsapp</v-icon>
              </v-btn>
            </template>
            <v-list class="text-center">
              <v-list-item href="tel:021231231" link>
                <v-list-item-title class="subtitle-2 font-weight-bold"
                  >اتصل الان</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="subtitle-2"
                  >02132123154</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn fab icon small :to="{ name: 'Home', query: { id: item.id } }">
            <v-icon small> visibility </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<style lang="scss" scoped></style>
<script>
export default {
  name: "viewCompaniesPlans",
  data() {
    return {
      search: "",
      closeOnContentClick: true,
      closeOnContentClick2: true,
      headers: [
        { text: "اسم الرزمة", value: "name", align: "center" },
        { text: "تاريخ الانشاء", value: "date", align: "center" },
        { text: "عدد الشركات", value: "companies", align: "center" },
        { text: "الملبغ", value: "cost", align: "center" },
        { text: "الحالة", value: "status", align: "center" },
        { text: "اجراءات", value: "actions", sortable: false, align: "center" },
      ],
      desserts: [
        {
          name: "الذهبية",
          date: "20-20-2020",
          companies: "30",
          cost: "20",
          status: 0,
        },
      ],
    };
  },
};
</script>
