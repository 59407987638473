<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!$store.getters.packages && Object.keys(keys).length == 0">
    </v-skeleton-loader>
    <v-card
      v-if="$store.getters.packages && Object.keys(keys).length != 0"
      class="text-center"
      elevation="0"
      outlined
    >
      <v-card-title class="font-weight-bold d-flex">
        <span class="order-1">{{ keys.packages_page_name }} </span>
        <v-spacer class="order-2"></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="keys.packages_page_search"
          single-line
          hide-details
          class="order-4 order-sm-3"
        ></v-text-field>
        <!--/ table search input -->
        <v-spacer class="order-4 d-none d-sm-block"></v-spacer>

        <v-tooltip bottom nudge-bottom="-5">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation="0"
              color="primary"
              class="order-3 order-sm-5"
              to="/addPackages"
              dark
              v-on="on"
              v-bind="attrs"
            >
              {{ keys.packages_page_btn }}
            </v-btn>
          </template>
          <span>{{ keys.packages_page_btn }}</span>
        </v-tooltip>
      </v-card-title>
      <!--/ card title -->
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        sort-by="calories"
        no-data-text="لا يوجد نتائج "
        no-results-text="لا يوجد نتائج بحث"
        :mobile-breakpoint="0"
        :footer-props="$store.state.dataTableFooterProps"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
        </template>
        <!--/creation at -->

        <template v-slot:[`item.is_active`]="{ item }">
          <v-chip
            x-small
            class="px-2 white--text"
            :class="[
              { 'red accent-1': !item.is_active },
              { primary: item.is_active },
            ]"
          >
            {{
              item.is_active
                ? keys.packages_page_status_active
                : keys.packages_page_status_not_active
            }}
          </v-chip>
        </template>

        <!--/ is active -->

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            fab
            icon
            small
            :to="{ name: 'viewCompaniesPlans', query: { id: item.id } }"
          >
            <v-icon small> visibility </v-icon>
          </v-btn>
          <!--/packge view -->

          <v-btn fab icon small @click="editItem(item)">
            <v-icon small> edit </v-icon>
          </v-btn>
          <!--/ edit action -->

          <v-btn fab small icon @click="deleteAlert(item.id)">
            <v-icon small>delete</v-icon>
          </v-btn>
          <!--/ delete action -->
        </template>
        <!--/actions  -->
      </v-data-table>
      <!--/ data table -->
    </v-card>
    <!--/ card -->

    <v-dialog max-width="500" v-model="dialog">
      <v-card>
        <v-card-title>{{ keys.packages_page_popup_edit_title }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-alert
            class="text-center"
            v-if="err"
            dense
            border="left"
            type="error"
          >
            {{ errText }}
          </v-alert>

          <v-tabs class="mb-3" v-model="tabs" background-color="gray">
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab
              v-model="tabs"
              v-for="(lang, i) in this.$store.getters.languages"
              :key="i"
            >
              {{ lang.native }}
            </v-tab>
          </v-tabs>
          <!--/tabs -->

          <v-tabs-items v-model="tabs">
            <v-tab-item
              v-for="(lang, i) in this.$store.getters.languages"
              :key="i"
            >
              <v-row
                :dir="lang.direction"
                :class="[
                  { 'text-end': lang.direction == 'ltr' },
                  { 'text-start': lang.direction == 'rtl' },
                ]"
              >
                <v-col cols="6">
                  <v-text-field
                    v-model="editItemObj.name[lang.locale]"
                    hide-details
                    :error="error"
                    :class="[
                      { 'right-label': lang.direction == 'rtl' },
                      { 'left-label': lang.direction == 'ltr' },
                    ]"
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.packages_page_package_name }}
                    </template>
                  </v-text-field>
                </v-col>
                <!--/ col -->
                <v-col cols="6">
                  <v-text-field
                    v-model="editItemObj.month_price"
                    hide-details
                    :class="[
                      { 'right-label': lang.direction == 'rtl' },
                      { 'left-label': lang.direction == 'ltr' },
                    ]"
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.packages_page_month_price }}
                    </template>
                  </v-text-field>
                </v-col>
                <!--/ col -->
                <v-col cols="6">
                  <v-text-field
                    v-model="editItemObj.annual_price"
                    hide-details
                    :class="[
                      { 'right-label': lang.direction == 'rtl' },
                      { 'left-label': lang.direction == 'ltr' },
                    ]"
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.packages_page_annual_price }}
                    </template>
                  </v-text-field>
                </v-col>
                <!--/ col -->
                <v-col cols="6">
                  <v-switch
                    v-model="editItemObj.is_active"
                    :label="`${keys.packages_page_status} (${
                      editItemObj.is_active
                        ? keys.packages_page_status_active
                        : keys.packages_page_status_not_active
                    })`"
                  >
                  </v-switch>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <!--/card text -->
        <v-card-actions>
          <v-btn @click="save()" :loading="load" depressed color="primary">{{
            keys.packages_page_popup_edit_actions_save
          }}</v-btn>
          <v-btn @click="close()" depressed text color="error">{{
            keys.packages_page_popup_edit_actions_close
          }}</v-btn>
        </v-card-actions>
        <!--/card actions -->
      </v-card>
      <!--/card -->
    </v-dialog>
    <!--/ edit dialog -->

    <v-dialog max-width="300" v-model="deleteDialog">
      <v-card>
        <v-card-title class="d-block text-center"
          >{{ keys.packages_page_delete_popup_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="loadDelete"
            :loading="loadDelete"
            @click="deletePackage()"
            depressed
            color="error"
            >{{ keys.packages_page_delete_popup_delete_btn }}</v-btn
          >
          <v-btn @click="deleteDialog = false" depressed>{{
            keys.packages_page_delete_popup_close_btn
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
  <!--/ container -->
</template>

<script>
export default {
  name: "packages",
  data() {
    return {
      tabs: null,
      err: false,
      errText: "",

      load: false,
      //start  props for delete types
      deleteDialog: false,
      loadDelete: false,
      deleteId: null,
      //End **************
      items: [
        { id: 1, name: "Item 1", description: "Item 1 description." },
        { id: 2, name: "Item 2", description: "Item 2 description." },
        { id: 3, name: "Item 3", description: "Item 3 description." },
        { id: 4, name: "Item 4", description: "Item 4 description." },
        { id: 5, name: "Item 1", description: "Item 1 description." },
        { id: 6, name: "Item 2", description: "Item 2 description." },
        { id: 7, name: "Item 3", description: "Item 3 description." },
        { id: 8, name: "Item 4", description: "Item 4 description." },
      ],
      error: false,
      loaded: false,
      dialog: false,
      search: "",
      dialog: false,
      id: null,
      editItemObj: {
        name: {},
        month_price: 0,
        annual_price: 0,
        is_active: false,
      },

    };
  },
  computed: {
    // get the all packages from vuex store after request
    desserts() {
      return this.$store.state.packages || [];
    },
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    headers() {
      return [
        {
          text: this.keys.packages_page_package_name,
          value: "name_locale",
          align: "center",
        },
        {
          text: this.keys.packages_page_creation_date,
          value: "created_at",
          align: "center",
        },
        {
          text: this.keys.packages_page_month_price,
          value: "month_price",
          align: "center",
        },
        {
          text: this.keys.packages_page_annual_price,
          value: "annual_price",
          align: "center",
        },
        {
          text: this.keys.packages_page_status,
          value: "is_active",
          align: "center",
        },
        {
          text: this.keys.packages_page_actions,
          value: "actions",
          sortable: false,
          align: "center",
        },
      ];
    },
  },
  methods: {
    // fade the dialog then geting the package  item which want to edit  then assign for editITemObj
    editItem(item) {
      this.dialog = true;
      this.indexItem = this.desserts.indexOf(item);
      this.editItemObj = Object.assign({}, item);
    },

    // save this edit
    save() {
      this.load = true;
      var data = JSON.stringify(this.editItemObj);
      var config = {
        method: "put",
        url: `packages/${this.editItemObj.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.$store.state.token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      this.axios(config)
        .then((res) => {
          Object.assign(this.desserts[this.indexItem], this.editItemObj);
          this.error = false;
          this.close();
        })
        .catch((err) => {
          this.load = false;
          this.error = true;
        });
    },

    // close dialog after  edit then  back the edit item to defualt  object
    close() {
      this.load = false;
      this.dialog = false;
    },
    // fade confirm alert and get  packge id
    deleteAlert(id) {
      this.deleteDialog = true;
      this.id = id;
    },
    // delete packges
    deletePackage() {
      this.loadDelete = true;
      var config = {
        method: "delete",
        url: `packages/${this.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.$store.state.token}`,
          "Content-Type": "application/json",
        },
      };
      this.axios(config).then((res) => {
        let indexId = this.desserts.findIndex((e) => e.id == this.id);
        this.desserts.splice(indexId, 1);
        this.deleteDialog = false;
        this.loadDelete = false;
      });
    },
  },
  mounted() {
    this.$store.dispatch("packages"); // ini the  data of packages
    let vm = this;
    window.onkeyup = function (e) {
      if (e.key == "Enter" && !vm.loadDelete) {
        vm.deletePackage();
      }
    };
  },
  // created() {
  //   let keys = this.$store.getters.page_trans_keys(this.$route.name).keys;

  //   this.headers = [
  //     {
  //       text: keys.packages_page_package_name,
  //       value: "name_locale",
  //       align: "center",
  //     },
  //     {
  //       text: keys.packages_page_creation_date,
  //       value: "created_at",
  //       align: "center",
  //     },
  //     {
  //       text: keys.packages_page_month_price,
  //       value: "month_price",
  //       align: "center",
  //     },
  //     {
  //       text: keys.packages_page_annual_price,
  //       value: "annual_price",
  //       align: "center",
  //     },
  //     { text: keys.packages_page_status, value: "is_active", align: "center" },
  //     {
  //       text: keys.packages_page_actions,
  //       value: "actions",
  //       sortable: false,
  //       align: "center",
  //     },
  //   ];
  // },
};
</script>
