var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-3",attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.translations.title)+" ")]),_c('v-card-text',[(_vm.err.prop)?_c('v-alert',{staticClass:"text-center",attrs:{"dense":"","border":"left","type":"error"}},[_vm._v(" "+_vm._s(_vm.err.text)+" ")]):_vm._e(),_c('v-tabs',{staticClass:"mb-3",attrs:{"background-color":"gray"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((this.$store.getters.languages),function(lang,i){return _c('v-tab',{key:i,model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_vm._v(" "+_vm._s(lang.native)+" ")])})],2),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((this.$store.getters.languages),function(lang,i){return _c('v-tab-item',{key:i},_vm._l((_vm.fields.fields),function(f,i){return _c('v-row',{key:i,staticClass:"align-end pb-2 me-1",class:[
              { 'text-end': lang.direction == 'ltr' },
              { 'text-start': lang.direction == 'rtl' } ],attrs:{"dir":lang.direction}},[_c('v-col',{attrs:{"cols":"6","sm":"5"}},[_c('v-text-field',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"hide-details":"","value":f.description[lang.locale],"error":_vm.tabs == 0 ? _vm.fields.fields[i].err.desc : null},on:{"input":function($event){return _vm.$emit('field_desc', {
                    prop_name: 'description',
                    section: 'fields',
                    value: $event,
                    locale: lang.locale,
                    ind: i,
                  })}}},[_c('template',{slot:"label"},[(_vm.tabs == 0)?_c('strong',{staticClass:"error--text"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.translations.field)+" ")])],2)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabs == 0),expression:"tabs == 0"}],attrs:{"cols":"6","sm":"3"}},[_c('v-select',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"hide-details":"","items":_vm.translations.field_type.items,"item-text":"text","item-value":"type","value":f.type,"error":f.err.type},on:{"change":function($event){return _vm.$emit('filed_type', {
                    prop_name: 'type',
                    section: 'fields',
                    value: $event,
                    ind: i,
                  })}}},[_c('template',{slot:"label"},[_c('strong',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.translations.field_type.label)+" ")])],2)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabs == 0),expression:"tabs == 0"}],attrs:{"cols":"6","sm":"3"}},[_c('v-select',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"hide-details":"","items":_vm.translations.field_status.items,"item-text":"text","item-value":"is_required","error":f.err.is_req,"value":f.is_required},on:{"change":function($event){return _vm.$emit('field_is_required', {
                    prop_name: 'is_required',
                    section: 'fields',
                    value: $event,
                    ind: i,
                  })}}},[_c('template',{slot:"label"},[_c('strong',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.translations.field_status.label)+" ")])],2)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabs == 0),expression:"tabs == 0"}],staticClass:"text-end",attrs:{"cols":"6","sm":"1"}},[_c('v-btn',{attrs:{"small":"","depressed":"","color":"error"},on:{"click":function($event){return _vm.$emit('remove_field', {
                    section: 'fields',
                    indx: i,
                    id: f.id,
                  })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("close")])],1)],1)],1)}),1)}),1),_c('v-btn',{staticClass:"mt-5 mt-md-3",attrs:{"outlined":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.$emit('new_field', { section: 'fields' })}}},[_vm._v(_vm._s(_vm.translations.new_btn))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }