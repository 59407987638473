<template>
  <v-app>
    <AsideMenu v-if="$route.name != 'login'"></AsideMenu>
    <AppHeader v-if="$route.name != 'login'"></AppHeader>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<style scoped></style>
<script>
import AppHeader from "./layout/header.vue";
import AsideMenu from "./layout/AsideMenu.vue";
import static_trans from "./mixins/static_trans";

export default {
  name: "App",
  data: () => ({
    page_keys: {
      pages: [],
    },
  }),
  components: {
    AppHeader,
    AsideMenu,
  },
  mixins: [static_trans],
  created() {
    if (
      this.$store.getters.loggedin &&
      this.$store.state.static_translations.length == 0 &&
      this.$route.name != "login" && this.$route.name != "translations"
    ) {
      // this.$store.dispatch("get_Static_Translate", this.$route.name)
      this.$store.dispatch("languages");
    }
  },
};
</script>
