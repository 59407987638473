<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!$store.state.companies">
    </v-skeleton-loader>

    <v-card
      v-if="$store.state.companies"
      class="text-center"
      elevation="0"
      outlined
    >
      <v-card-title class="font-weight-bold d-flex align-center">
        <span class="order-1">{{ keys.compaines_page_name }}</span>
        <v-spacer class="order-2"></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="keys.compaines_page_search_input"
          single-line
          hide-details
          class="order-4 order-sm-3"
        ></v-text-field>
        <!--/search -->
        <v-spacer class="order-4 d-none d-sm-block"></v-spacer>

        <v-btn
          @click="addCompany()"
          depressed
          color="primary"
          class="order-3 order-sm-5"
          >{{ keys.compaines_page_btn_add }}</v-btn
        >
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ dialogTitle }}</span>
            </v-card-title>
            <!--/card title -->
            <v-divider></v-divider>

            <v-card-text>
              <v-alert
                class="text-center"
                v-if="err.errAlert"
                dense
                border="left"
                type="error"
              >
                {{ err.errText }}
              </v-alert>
              <v-tabs class="mb-3" v-model="tabs" background-color="gray">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab
                  v-model="tabs"
                  v-for="(lang, i) in this.$store.getters.languages"
                  :key="i"
                >
                  {{ lang.native }}
                </v-tab>
              </v-tabs>
              <!--/tabs -->
              <v-tabs-items v-model="tabs">
                <v-tab-item
                  v-for="(lang, i) in this.$store.getters.languages"
                  :key="i"
                >
                  <v-row
                    class="mb-2"
                    :dir="lang.direction"
                    :class="[
                      { 'text-end': lang.direction == 'ltr' },
                      { 'text-start': lang.direction == 'rtl' },
                    ]"
                  >
                    <v-col cols="12" md="6">
                      <v-text-field
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        hide-details
                        v-model="editItemObj.name[lang.locale]"
                        :error="tabs == 0 ? err.name : null"
                      >
                        <template slot="label">
                          <strong v-if="tabs == 0" class="error--text"
                            >*</strong
                          >
                          {{ keys.compaines_page_company_name }}
                        </template>
                      </v-text-field>
                      <p class="error--text" v-if="err.name && tabs == 0">
                        {{ err.errText.name }}
                      </p>
                    </v-col>
                    <!--/col -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        hide-details
                        v-model="editItemObj.owner_name[lang.locale]"
                        :error="tabs == 0 ? err.owner_name : null"
                      >
                        <template slot="label">
                          <strong v-if="tabs == 0" class="error--text"
                            >*</strong
                          >
                          {{ keys.compaines_page_owner_name }}
                        </template>
                      </v-text-field>
                      <p class="error--text" v-if="err.owner_name && tabs == 0">
                        {{ err.errText.owner_name }}
                      </p>
                    </v-col>
                    <!--/col -->

                    <v-col cols="12" md="6">
                      <v-text-field
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        hide-details
                        v-model="editItemObj.id_number"
                        :error="tabs == 0 ? err.id_number : null"
                      >
                        <template slot="label">
                          <strong v-if="tabs == 0" class="error--text"
                            >*</strong
                          >
                          ID number
                        </template>
                      </v-text-field>
                      <p class="error--text" v-if="err.id_number && tabs == 0">
                        {{ err.errText.id_number }}
                      </p>
                    </v-col>
                    <!--/col -->

                    <v-col cols="12" md="6" v-if="tabs == 0">
                      <v-text-field
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        hide-details
                        v-model="editItemObj.phone"
                        :error="err.phone"
                      >
                        <template slot="label">
                          <strong class="error--text">*</strong>
                          {{ keys.compaines_page_phone }}
                        </template>
                      </v-text-field>
                      <p class="error--text" v-if="err.phone">
                        {{ err.errText.phone }}
                      </p>
                    </v-col>
                    <!--/col -->
                    <v-col cols="12" md="6" v-if="tabs == 0">
                      <v-text-field
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        hide-details
                        v-model="editItemObj.whatsapp"
                        :error="err.whatsapp"
                      >
                        <template slot="label">
                          {{ keys.compaines_page_whatsapp }}
                        </template>
                      </v-text-field>
                      <p class="error--text" v-if="err.whatsapp">
                        {{ err.errText.whatsapp }}
                      </p>
                    </v-col>
                    <!--/col -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        hide-details
                        v-model="editItemObj.country[lang.locale]"
                        :error="tabs == 0 ? err.country : null"
                      >
                        <template slot="label">
                          <strong v-if="tabs == 0" class="error--text"
                            >*</strong
                          >
                          {{ keys.compaines_page_country }}
                        </template>
                      </v-text-field>
                      <p
                        class="error--text"
                        v-if="tabs == 0 ? err.country : null"
                      >
                        {{ err.errText.country }}
                      </p>
                    </v-col>
                    <!--/col -->
                    <v-col cols="12" md="6" v-if="tabs == 0">
                      <v-text-field
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        type="password"
                        hide-details
                        v-model="editItemObj.password"
                        :error="err.password"
                      >
                        <template slot="label">
                          <strong class="error--text">*</strong>
                          {{ keys.compaines_page_password }}
                        </template>
                      </v-text-field>
                      <p class="error--text" v-if="err.password">
                        {{ err.errText.password }}
                      </p>
                    </v-col>
                    <!--/col -->
                    <v-col cols="12" md="6" v-if="tabs == 0">
                      <v-text-field
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        type="email"
                        hide-details
                        v-model="editItemObj.email"
                        :error="err.email"
                      >
                        <template slot="label">
                          <strong class="error--text">*</strong>
                          {{ keys.compaines_page_email }}
                        </template>
                      </v-text-field>
                      <p class="error--text" v-if="err.email">
                        {{ err.errText.email }}
                      </p>
                    </v-col>
                    <!--/col -->
                    <v-col cols="12" md="6" v-if="tabs == 0">
                      <v-select
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        v-model="editItemObj.package_id"
                        hide-details
                        :items="packages"
                        item-text="name_locale"
                        item-value="id"
                        :error="err.package_id"
                      >
                        <template slot="label">
                          <strong class="error--text">*</strong>
                          {{ keys.compaines_page_package }}
                        </template>
                      </v-select>
                      <p class="error--text" v-if="err.package_id">
                        {{ err.errText.package_id }}
                      </p>
                    </v-col>
                    <!--/col -->
                    <v-col cols="12" md="6" v-if="tabs == 0">
                      <v-select
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        v-model="editItemObj.department_id"
                        hide-details
                        :items="departments"
                        item-text="name_locale"
                        item-value="id"
                        :error="err.department_id"
                      >
                        <template slot="label">
                          <strong class="error--text">*</strong>
                          {{ keys.compaines_page_department_id }}
                        </template>
                      </v-select>
                      <p class="error--text" v-if="err.department_id">
                        {{ err.errText.department_id }}
                      </p>
                    </v-col>
                    <!--/col -->
                    <v-col cols="12" md="6" v-if="tabs == 0">
                      <v-select
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        item-value="id"
                        item-text="name_locale"
                        hide-details
                        :items="project_types_ids"
                        v-model="editItemObj.project_types_ids"
                        multiple
                        :error="err.project_types_ids"
                      >
                        <template slot="label">
                          <strong class="error--text">*</strong>
                          {{ keys.compaines_page_projects_types }}
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <v-chip
                            style="height: 24px"
                            class="py-0"
                            v-if="index === 0 || index === 1"
                          >
                            <span>{{ item.name_locale }}</span>
                          </v-chip>
                          <span
                            v-if="index === 2"
                            class="grey--text text-caption"
                          >
                            (+{{ editItemObj.project_types_ids.length - 2 }}
                            others)
                          </span>
                        </template>
                      </v-select>
                      <p class="error--text" v-if="err.project_types_ids">
                        {{ err.errText.project_types_ids }}
                      </p>
                    </v-col>
                    <!--/col -->

                    <v-col cols="12" v-if="tabs == 0">
                      <v-file-input
                        v-model="editItemObj.image"
                        hide-details
                        :error="err.image"
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                      >
                        <template slot="label">
                          <strong v-if="indexItem == -1" class="error--text"
                            >*</strong
                          >
                          {{ keys.compaines_page_image }}
                        </template>
                      </v-file-input>
                      <p class="error--text" v-if="err.image">
                        {{ err.errText.image }}
                      </p>
                    </v-col>
                    <!--/col -->
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
            <!--/card text -->

            <v-card-actions>
              <v-btn
                @click="save()"
                color="primary"
                class="font-weight-bold"
                text
                small
                :loading="load"
              >
                {{ keys.compaines_page_save_btn }}
              </v-btn>
              <v-btn
                small
                @click="close()"
                class="font-weight-bold"
                color="red"
                text
                ref="closeBtn"
              >
                {{ keys.compaines_page_close_btn }}
              </v-btn>
            </v-card-actions>
            <!--/card actions -->
          </v-card>
          <!--/card -->
        </v-dialog>
        <!--/dialog -->
      </v-card-title>
      <!--/card title -->
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        sort-by="calories"
        no-data-text="لا يوجد نتائج "
        no-results-text="لا يوجد نتائج بحث"
        :mobile-breakpoint="0"
        :footer-props="$store.state.dataTableFooterProps"
      >
        <template v-slot:[`item.name_locale`]="{ item }">
          <div class="d-flex align-center">
            <img :src="item.src" width="50" class="me-2" />
            {{ item.name_locale }}
          </div>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y :close-on-content-click="closeOnContentClick">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                fab
                small
                v-bind="attrs"
                v-on="on"
                @click="closeOnContentClick = !closeOnContentClick"
              >
                <v-icon small>call</v-icon>
              </v-btn>
            </template>
            <v-list class="text-center">
              <v-list-item outlined :href="`tel:${item.phone}`" link>
                <v-list-item-title class="subtitle-2 font-weight-bold">{{
                  keys.compaines_page_phone_call
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="subtitle-2">{{
                  item.phone
                }}</v-list-item-title>
              </v-list-item>
              <!--/list item  -->
            </v-list>
            <!--/v list -->
          </v-menu>
          <!--/menu -->

          <v-menu
            v-if="item.whatsapp"
            offset-y
            :close-on-content-click="closeOnContentClick2"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                class="mx-2"
                icon
                v-bind="attrs"
                v-on="on"
                @click="closeOnContentClick = !closeOnContentClick2"
              >
                <v-icon small>whatsapp</v-icon>
              </v-btn>
            </template>
            <v-list class="text-center">
              <v-list-item
                :href="`https://api.whatsapp.com/send?phone=${item.whatsapp}:`"
                link
              >
                <v-list-item-title class="subtitle-2 font-weight-bold">{{
                  keys.compaines_page_phone_call
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="subtitle-2">{{
                  item.whatsapp
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!--/menu -->

          <v-btn fab small icon>
            <v-icon small>badge</v-icon>
          </v-btn>

          <v-btn fab icon small @click="editItem(item)">
            <v-icon small> edit </v-icon>
          </v-btn>
          <v-btn fab icon small @click="getDeleteId(item.id)">
            <v-icon small> delete </v-icon>
          </v-btn>
        </template>
        <!--/template -->
      </v-data-table>
      <!--/data table -->
    </v-card>
    <!--card -->

    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          loading....
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--/loading dialog -->

    <v-dialog max-width="300" v-model="deleteDialog">
      <v-card>
        <v-card-title class="text-center d-block"
          >{{ keys.compaines_page_delete_popup_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="loadDelete"
            :loading="loadDelete"
            @click="deleteCompany()"
            depressed
            color="error"
            >{{ keys.compaines_page_delete_popup_btn_delete }}</v-btn
          >
          <v-btn @click="deleteDialog = false" depressed>{{
            keys.compaines_page_delete_popup_btn_close
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
</template>

<script>
export default {
  name: "telBook",
  data() {
    return {
      tabs: 0,
      id: 0,
      loadDelete: false,
      deleteDialog: false,
      loading: false,
      regMail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      err: {
        errText: {
          name: "",
          owner_name: "",
          id_number: "",
          phone: "",
          whatsapp: "",
          password: "",
          package_id: "",
          country: "",
          project_types_ids: "",
          department_id: "",
          image: "",
        },
        name: false,
        owner_name: false,
        id_number: false,
        phone: false,
        whatsapp: false,
        password: false,
        package_id: false,
        country: false,
        project_types_ids: false,
        email: false,
        department_id: false,
        errAlert: false,
        image: false,
      },
      errDefault: {
        errText: {
          name: "",
          owner_name: "",
          id_number: "",
          phone: "",
          whatsapp: "",
          password: "",
          package_id: "",
          country: "",
          project_types_ids: "",
          department_id: "",
          image: "",
        },
        errAlert: false,
        name: false,
        owner_name: false,
        phone: false,
        whatsapp: false,
        password: false,
        package_id: false,
        country: false,
        project_types_ids: false,
        department_id: false,
        email: false,
        image: false,
      },

      closeOnContentClick: true,
      closeOnContentClick2: true,
      dialog: false,
      search: "",
      dialog: false,
      load: false,

      editItemObj: {
        name: {},
        owner_name: {},
        country: {},
        project_types_ids: [],
        phone: "",
        whatsapp: "",
        password: "",
        package_id: "",
        email: "",
        department_id: "",
        image: null,
        id_number: "",
      },
      defualtItem: {
        name: {},
        owner_name: {},
        country: {},
        project_types_ids: [],
        phone: "",
        whatsapp: "",
        password: "",
        package_id: "",
        email: "",
        department_id: "",
        image: null,
        id_number: "",
      },
      indexItem: -1,
    };
  },
  watch: {
    dialog(newvalue, oldvalue) {
      if (!newvalue && oldvalue) {
        this.close();
      }
    },
  },
  computed: {
    dialogTitle() {
      return this.indexItem == -1
        ? this.keys.compaines_page_add_title
        : this.keys.compaines_page_edit_title;
    },
    desserts() {
      return this.$store.state.companies || []; // get all categories from vuex store
    },
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    headers() {
      return [
        {
          text: this.keys.compaines_page_company_name,
          value: "name_locale",
        },
        {
          text: this.keys.compaines_page_owner_name,
          value: "owner_name_locale",
        },
        {
          text: this.keys.compaines_page_country,
          value: "country_locale",
        },
        {
          text: this.keys.compaines_page_creation_date,
          value: "created_at",
        },
        {
          text: this.keys.compaines_page_actions,
          value: "actions",
          sortable: false,
          align: "center",
        },
      ];
    },
    packages() {
      return this.$store.state.packages || []; // get all categories from vuex store
    },
    project_types_ids() {
      return this.$store.state.projectTypes || []; // get all categories from vuex store
    },
    departments() {
      return this.$store.state.departments || []; // get all categories from vuex store
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let cats = this.$store.state.companies;
      let packages = this.$store.state.packages;
      let projectTypes = this.$store.state.projectTypes;
      let departments = this.$store.state.departments;
      if (!cats) {
        await this.$store.dispatch("companies");
      }
      if (!packages) {
        await this.$store.dispatch("packages");
      }
      if (!projectTypes) {
        await this.$store.dispatch("projectTypes");
      }
      if (!departments) {
        await this.$store.dispatch("departments");
      }
    },
    addCompany() {
      this.fetchData();
      this.dialog = true;
      this.loading = false;
    },

    editItem(item) {
      this.indexItem = this.desserts.indexOf(item);

      this.editItemObj = Object.assign({}, this.editItemObj, item);
      this.fetchData();
      this.dialog = true;
      this.loading = false;
    },
    valid() {
      let errs = [];
      let default_lang_locale = this.$store.getters.languages.find(
        (e) => e.is_default
      ).locale;

      Object.entries(this.editItemObj).forEach((e, a) => {
        if (e[0] == "name" || e[0] == "country" || e[0] == "owner_name") {
          if (!e[1][default_lang_locale]) {
            this.tabs = 0;
            this.err[e[0]] = true;
            this.err.errText[e[0]] = this.keys[`compaines_page_empty_${e[0]}`];
            errs.push(e[0]);
          } else {
            this.err[e[0]] = false;
          }
        } else if (e[0] == "email") {
          if (!this.regMail.test(e[1])) {
            this.tabs = 0;
            this.err[e[0]] = true;
            this.err.errText[e[0]] =
              this.keys["compaines_page_email_validation"];
            errs.push(e[0]);
          } else {
            this.err[e[0]] = false;
          }
        } else if (e[0] == "password") {
          if (!e[1]) {
            this.tabs = 0;
            this.err[e[0]] = true;
            this.err.errText[e[0]] = this.keys[`compaines_page_empty_${e[0]}`];
            errs.push(e[0]);
          } else {
            this.err[e[0]] = false;
          }
        } else if (e[0] == "id_number") {
          if (!e[1]) {
            this.tabs = 0;
            this.err[e[0]] = true;
            this.err.errText[e[0]] = "required";
            errs.push(e[0]);
          } else {
            this.err[e[0]] = false;
          }
        } else if (e[0] == "image" && this.indexItem == -1) {
          if (!e[1]) {
            this.tabs = 0;
            this.err[e[0]] = true;
            this.err.errText[e[0]] = this.keys[`compaines_page_empty_${e[0]}`];
            errs.push(e[0]);
          } else {
            this.err[e[0]] = false;
          }
        } else if (e[0] == "whatsapp") {
          this.err[e[0]] = false;
        } else if (e[0] == "project_types_ids") {
          if (e[1].length == 0) {
            this.tabs = 0;
            this.err[e[0]] = true;
            this.err.errText[e[0]] = this.keys[`compaines_page_empty_${e[0]}`];
            errs.push(e[0]);
          } else {
            this.err[e[0]] = false;
          }
        } else if (
          e[0] == "phone" ||
          e[0] == "package_id" ||
          e[0] == "department_id"
        ) {
          if (e[1] == "") {
            this.tabs = 0;
            this.err[e[0]] = true;
            this.err.errText[e[0]] = this.keys[`compaines_page_empty_${e[0]}`];
            errs.push(e[0]);
          } else {
            this.err[e[0]] = false;
          }
        }
      });
      return errs.length == 0 ? true : false;
    },
    save() {
      if (this.valid()) {
        this.load = true;
        let formData = new FormData();
        Object.entries(this.editItemObj).forEach((e) => {
          if (e[0] == "name") {
            Object.entries(e[1]).forEach((n) => {
              formData.append(`name[${n[0]}]`, n[1]);
            });
          } else if (e[0] == "owner_name") {
            Object.entries(e[1]).forEach((n) => {
              formData.append(`owner_name[${n[0]}]`, n[1]);
            });
          } else if (e[0] == "country") {
            Object.entries(e[1]).forEach((n) => {
              formData.append(`country[${n[0]}]`, n[1]);
            });
          } else if (e[0] == "project_types_ids") {
            Object.entries(e[1]).forEach((n, i) => {
              formData.append(`project_types_ids[${i}]}]`, n[1]);
            });
          } else {
            formData.append(`${e[0]}`, e[1]);
          }
        });

        if (this.indexItem > -1) {
          let config = {
            method: "post",
            url: `companies/${this.editItemObj.id}?_method=PUT`,
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`,
              "Content-Type": "multipart/form-data",
              locale: this.$store.state.locale,
            },
            data: formData,
          };
          this.axios(config)
            .then((res) => {
              this.$store.dispatch("companies");
              this.close();
            })
            .catch((err) => {
              console.log(err.response);
              this.load = false;
            });
        } else {
          let config = {
            method: "post",
            url: "companies",
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`,
              "Content-Type": "multipart/form-data",
              locale: this.$store.state.locale,
            },
            data: formData,
          };
          this.axios(config)
            .then((res) => {
              this.$store.dispatch("companies");
              this.close();
            })
            .catch((err) => {
              console.log(err.response);
              this.load = false;
            });
        }
      }
    },
    // get delete id then assign for prop to use it
    getDeleteId(id) {
      this.deleteId = id;
      this.deleteDialog = true;
    },
    // delete company
    deleteCompany() {
      this.loadDelete = true; // make delete button start loading
      let config = {
        method: "delete",
        url: `companies/${this.deleteId}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.$store.state.token}`,
        },
      };
      this.axios(config).then((res) => {
        let findindex = this.desserts.findIndex((e) => e.id == this.deleteId); // get index of  category delete for delete form desserts
        this.desserts.splice(findindex, 1);
        this.deleteDialog = false;
        this.loadDelete = false;
      });
    },
    close() {
      this.indexItem = -1;
      this.tabs = 0;
      this.dialog = false;
      this.load = false;
      this.$nextTick(() => {
        Object.entries(this.editItemObj).forEach((e) => {
          if (e[0] == "name" || e[0] == "owner_name" || e[0] == "country") {
            this.editItemObj[e[0]] = {};
          } else if (Array.isArray(e[1])) {
            this.editItemObj[e[0]] = [];
          } else if (typeof e[1] == "string") {
            this.editItemObj[e[0]] = "";
          } else {
            this.editItemObj[e[0]] = null;
          }
        }); // back the editItemObj to defualt
      });
    },
  },
  mounted() {
    let companies = this.$store.state.companies;
    if (!companies) {
      this.$store.dispatch("companies");
    }
    let vm = this;
    window.onkeyup = function (e) {
      // make delete action in enter key
      if (e.key == "Enter") {
        vm.deleteCompany();
      }
    };
  },
};
</script>
