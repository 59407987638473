<template>
  <v-container>
    <v-card class="text-center" elevation="0" outlined>
      <v-card-title class="font-weight-bold d-flex">
        <span class="order-1">الموظفين </span>
        <v-spacer class="order-2"></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="بحث"
          single-line
          hide-details
          class="order-4 order-sm-3"
        ></v-text-field>
        <v-spacer class="order-4 d-none d-sm-block"></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on : dialog, attrs }">
            <v-tooltip bottom nudge-bottom="-5">
              <template v-slot:activator="{ on : tooltip }">
                <v-btn
                  elevation="0"
                  color="primary"
                  class="order-3 order-sm-5"
                  v-bind="attrs"
                  v-on="{...dialog,...tooltip}"
                >
                  اضافة موظف
                </v-btn>
              </template>
              <span>اضافة</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ dialogTitle }}</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="الأسم"
                    hide-details
                    v-model="editItemObj.name"
                  ></v-text-field>
                </v-col>
                <!--/col -->
                <v-col cols="12" md="6">
                  <v-text-field hide-details label="رقم الهوية"></v-text-field>
                </v-col>
                <!--/col -->
                <v-col cols="12" md="6">
                  <v-select hide-details label="نوع الموظف"></v-select>
                </v-col>
                <!--/col -->
                <v-col cols="12" md="6">
                  <v-text-field
                    label=" الهاتف"
                    hide-details
                    v-model="editItemObj.phone"
                  ></v-text-field>
                </v-col>
                <!--/col -->
                <v-col cols="12" md="6">
                  <v-text-field
                    hide-details
                    label=" الواتساب"
                    v-model="editItemObj.whatsapp"
                  ></v-text-field>
                </v-col>
                <!--/col -->
                <v-col cols="12" md="6">
                  <v-text-field
                    hide-details
                    label=" password"
                    type="password"
                    v-model="editItemObj.password"
                  ></v-text-field>
                </v-col>
                <!--/col -->
                <v-col cols="12">
                  <v-select
                    hide-details
                    :items="items"
                    chips
                    label="الصلاحيات"
                    multiple
                    v-model="editItemObj.permations"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
                <!--/col -->
              </v-row>
              <!--/row -->
            </v-card-text>
            <!--/card text -->

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="close()" class="font-weight-bold" color="red" text>
                الغاء
              </v-btn>
              <v-btn
                @click="save(), (dialog = false)"
                color="primary"
                class="font-weight-bold"
                text
              >
                حفظ
              </v-btn>
            </v-card-actions>
            <!--/card actions -->
          </v-card>
          <!--/card -->
        </v-dialog>
        <!--/dialog -->
      </v-card-title>
      <!--/card title -->
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        sort-by="calories"
        no-data-text="لا يوجد نتائج "
        no-results-text="لا يوجد نتائج بحث"
        :footer-props="$store.state.dataTableFooterProps"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y :close-on-content-click="closeOnContentClick">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                fab
                small
                v-bind="attrs"
                v-on="on"
                @click="closeOnContentClick = !closeOnContentClick"
              >
                <v-icon small>call</v-icon>
              </v-btn>
            </template>
            <v-list class="text-center">
              <v-list-item outlined href="tel:021231231" link>
                <v-list-item-title class="subtitle-2 font-weight-bold"
                  >اتصل الان</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="subtitle-2"
                  >02132123154</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <!--/menu -->
          <v-menu offset-y :close-on-content-click="closeOnContentClick2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                class="mx-2"
                icon
                v-bind="attrs"
                v-on="on"
                @click="closeOnContentClick = !closeOnContentClick2"
              >
                <v-icon small>whatsapp</v-icon>
              </v-btn>
            </template>
            <v-list class="text-center">
              <v-list-item href="tel:021231231" link>
                <v-list-item-title class="subtitle-2 font-weight-bold"
                  >اتصل الان</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="subtitle-2"
                  >02132123154</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <!--/menu -->

          <v-btn fab small icon :to="{ name: 'Home', query: { id: item.id } }">
            <v-icon small>badge</v-icon>
          </v-btn>
          <v-btn fab icon small @click="editItem(item)">
            <v-icon small> edit </v-icon>
          </v-btn>
          <v-btn fab icon small>
            <v-icon small> delete </v-icon>
          </v-btn>
        </template>
        <!--/template-->
      </v-data-table>
      <!--/data table -->
    </v-card>
    <!--/card -->
  </v-container>
  <!--/container -->
</template>

<script>
export default {
  name: "Employees",
  data() {
    return {
      items: [
        { id: 1, name: "Item 1", description: "Item 1 description." },
        { id: 2, name: "Item 2", description: "Item 2 description." },
        { id: 3, name: "Item 3", description: "Item 3 description." },
        { id: 4, name: "Item 4", description: "Item 4 description." },
        { id: 5, name: "Item 1", description: "Item 1 description." },
        { id: 6, name: "Item 2", description: "Item 2 description." },
        { id: 7, name: "Item 3", description: "Item 3 description." },
        { id: 8, name: "Item 4", description: "Item 4 description." },
      ],
      form: {
        id: null,
        name: "",
        description: "",
      },
      plans: ["plan1", "plan2"],
      closeOnContentClick: true,
      closeOnContentClick2: true,
      dialog: false,
      search: "",
      dialog: false,
      today: new Date(),
      editItemObj: {
        name: "",
        employeeType: "",
        phone: "",
        whatsapp: "",
        passowrd: "",
        permations: [],
      },
      defualtItem: {
        name: "",
        employeeType: "",
        phone: "",
        whatsapp: "",
        passowrd: "",
        permations: [],
      },
      indexItem: -1,
      headers: [
        { text: "الأسم", value: "name", align: "center" },
        { text: "نوع المستخدم", value: "employeeType", align: "center" },
        { text: "الهاتف", value: "phone", align: "center" },
        { text: "واتساب", value: "whatsapp", align: "center" },
        { text: "اجراءات", value: "actions", sortable: false, align: "center" },
      ],
      desserts: [
        {
          name: "حسن احمد",
          employeeType: "موظف",
          phone: "01212122",
          whatsapp: "01212122",
          passowrd: "",
          permations: [],
        },
      ],
    };
  },
  computed: {
    dialogTitle() {
      return this.indexItem == -1 ? "اضافة موظف" : "تعديل موظف";
    },
  },
  methods: {
    editItem(item) {
      this.dialog = true;
      this.indexItem = this.desserts.indexOf(item);
      this.editItemObj = Object.assign({}, item);
    },
    save() {
      if (this.indexItem > -1) {
        Object.assign(this.desserts[this.indexItem], this.editItemObj);
      } else {
        this.desserts.push(this.editItemObj);
      }
      this.close();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editItemObj = Object.assign({}, this.defualtItem);
        this.indexItem = -1;
      });
    },
  },
};
</script>
