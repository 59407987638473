<template>
  <v-container>

    <!--/  this loading before request  then it will be removed after request is done -->
    <v-skeleton-loader type="table" v-if="!$store.state.projectTypes">
    </v-skeleton-loader>
    <v-card
      v-if="$store.state.projectTypes"
      class="text-center"
      elevation="0"
      outlined
    >
      <v-card-title class="d-flex">
        <span class="order-1">{{ keys.projects_types_page_name }}</span>
        <v-spacer class="order-2"></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="keys.projects_types_page_search"
          single-line
          hide-details
          class="order-4 order-sm-3"
        ></v-text-field>
        <!--/search table -->
        <v-spacer class="order-4 d-none d-sm-block"></v-spacer>

        <!--start add and edit dialog -->
        <v-dialog v-model="dialog" max-width="500">
          <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip bottom nudge-bottom="-5">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-bind="attrs"
                  v-on="{ ...dialog, ...tooltip }"
                  elevation="0"
                  class="order-3 order-sm-5"
                  color="primary"
                  >{{ keys.projects_types_page_btn }}</v-btn
                >
              </template>
              <span>{{ keys.projects_types_page_btn }}</span>
            </v-tooltip>
          </template>
          <v-card elevation="0" outlined>
            <v-card-title class="font-weight-bold">
              {{ dialogTitle }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-4">
              <v-alert
                class="text-center"
                v-if="err"
                dense
                border="left"
                type="error"
              >
                {{ errText }}
              </v-alert>
              <v-tabs class="mb-2" v-model="tabs" background-color="gray">
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab
                  v-model="tabs"
                  v-for="(lang, i) in this.$store.getters.languages"
                  :key="i"
                >
                  {{ lang.native }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tabs">
                <v-tab-item
                  v-for="(lang, i) in this.$store.getters.languages"
                  :key="i"
                >
                  <v-row
                    :dir="lang.direction"
                    :class="[
                      { 'text-end': lang.direction == 'ltr' },
                      { 'text-start': lang.direction == 'rtl' },
                    ]"
                  >
                    <v-col cols="12">
                      <v-text-field
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        :error="i == 0 ? errName : null"
                        v-model="editItemObj.name[lang.locale]"
                      >
                        <template slot="label">
                          <strong class="error--text">*</strong>
                          {{ keys.projects_types_page_type_name }}
                        </template>
                      </v-text-field>
                      <!--/project type name -->
                    </v-col>
                    <!--/ col -->
                    <v-col cols="12" class="mb-1">
                      <v-select
                        :menu-props="{ offsetY: true }"
                        v-if="tabs == 0"
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        v-model="editItemObj.categories_ids"
                        multiple
                        hide-details
                        :items="categories"
                        item-text="name_locale"
                        item-value="id"
                        :error="errCats"
                      >
                        <template slot="label">
                          <strong class="error--text">*</strong>
                          {{ keys.projects_types_page_categories_names }}
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <v-chip
                            style="height: 24px"
                            class="py-0"
                            v-if="index === 0"
                          >
                            <span>{{ item.name_locale }}</span>
                          </v-chip>
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                          >
                            (+{{ editItemObj.categories_ids.length - 1 }}
                            others)
                          </span>
                        </template>
                      </v-select>
                      <!--/ select  -->
                    </v-col>
                    <!--/col  select categoris  -->
                  </v-row>
                  <!--/row -->
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
            <v-card-actions>
              <v-btn
                @click="save()"
                text
                color="primary"
                class="font-weight-bold"
                small
                :loading="load"
                :disabled="load"
                >{{ keys.projects_types_popup_pro_save }}</v-btn
              >
              <!--/ save data -->
              <v-btn
                @click="dialog = false"
                text
                color="red"
                class="font-weight-bold"
                small
                >{{ keys.projects_types_popup_pro_close }}</v-btn
              >
            </v-card-actions>
            <!--/card actions -->
          </v-card>
        </v-dialog>
        <!--/end add and edit dialog -->
      </v-card-title>
      <!--/end card title -->

      <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        sort-by="calories"
        no-data-text="لا يوجد نتائج "
        no-results-text="لا يوجد نتائج بحث"
        :footer-props="$store.state.dataTableFooterProps"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
        </template>
        <!--/created at -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="editType(item)" fab small icon>
            <v-icon small>edit</v-icon>
          </v-btn>
          <!--/ edit action -->
          <v-btn @click="getDeleteId(item.id)" fab small icon>
            <v-icon small>delete</v-icon>
          </v-btn>
          <!--/ delete action -->
        </template>

        <template v-slot:[`item.categories`]="{ item }">
          <v-btn
            @click="dialogCats(item.categories)"
            elevation="0"
            rounded
            small
            class="me-2"
          >
            {{ keys.projects_types_page_categories_names }}
            {{ item.categories.length }}
          </v-btn>
          <!--/view slected categories -->
        </template>
      </v-data-table>
      <!--/end datatable -->
    </v-card>

    <!--start dialog (showing what project type has categories ) -->
    <v-dialog max-width="300" v-model="categoriesDialgo.modal">
      <v-card>
        <v-card-title> الفئات </v-card-title>
        <!--/card title -->
        <v-card-text>
          <v-chip-group active-class="primary--text" column>
            <v-chip v-for="name in categoriesDialgo.names" :key="name.name">
              {{ name.name }}
            </v-chip>
          </v-chip-group>
        </v-card-text>
        <!--/card text -->
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text color="error" @click="categoriesDialgo.modal = false">الغاء</v-btn>
          <!--/close -->
        </v-card-actions>
        <!--/card actions -->
      </v-card>
    </v-dialog>

    <!-- start delete  type dialog -->
    <v-dialog max-width="300" v-model="deleteDialog">
      <v-card>
        <v-card-title class="text-center d-block"
          >{{ keys.projects_types_page_delete_popup_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="loadDelete"
            :loading="loadDelete"
            @click="deleteType()"
            depressed
            color="error"
            >{{ keys.projects_types_page_delete_popup_delete_btn }}</v-btn
          >
          <v-btn @click="deleteDialog = false" depressed>{{
            keys.projects_types_page_delete_popup_close_btn
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
</template>

<script>
export default {
  name: "typeOfProjects",

  data() {
    return {
      tabs: null,
      err: false,
      errText: "",
      errName: false,
      errCats: false,

      //start  props for delete types
      deleteDialog: false,
      loadDelete: false,
      deleteId: null,
      //End **************

      load: false,

      // dialog showing all categories what inside the project type
      categoriesDialgo: {
        modal: false,
        names: [],
      },
      cats: null,
      //  Add and edit project types
      dialog: false,
      indexItem: -1,
      editItemObj: {
        name: {},
        categories_ids: [],
      },
      defualtItem: {
        name: {},
        categories_ids: [],
      },

      search: "", // table search prop
    };
  },
  watch: {
    dialog(newvalue, oldvalue) {
      if (!newvalue && oldvalue) this.close();
    },
  },
  computed: {
    dialogTitle() {
      return this.indexItem == -1
        ? this.keys.projects_types_popup_add_title
        : this.keys.projects_types_popup_edit_title;
    },
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    desserts() {
      return this.$store.state.projectTypes || []; // get  types from vuex store
    },
    categories() {
      return this.$store.state.categories || []; // get categories from vuex store then  rendering into select
    },
    headers() {
      return [
        {
          text: this.keys.projects_types_page_pro_num,
          value: "id",
          align: "center",
        },
        {
          text: this.keys.projects_types_page_pro_name,
          value: "name_locale",
          align: "center",
        },
        {
          text: this.keys.projects_types_page_pro_creation_date,
          value: "created_at",
          align: "center",
        },
        {
          text: this.keys.projects_types_page_categories_names,
          value: "categories",
          align: "center",
        },
        {
          text: this.keys.projects_types_page_actions,
          value: "actions",
          sortable: false,
          align: "center",
        },
      ];
    },
  },
  methods: {
    dialogCats(item) {
      // viwe all selected categoris for this type
      this.categoriesDialgo.modal = true;
      this.categoriesDialgo.names = item;
    },
    // fade the dialog then geting the type   item which want to edit  then assign for editITemObj

    editType(item) {
      this.indexItem = this.desserts.indexOf(item);
      this.editItemObj = Object.assign({}, item);
      this.editItemObj.categories_ids = [];
      this.editItemObj.categories.forEach((element) => {
        this.editItemObj.categories_ids.push(element.id);
      });
      this.dialog = true;
    },

    // make validation before request edit or create if name empty || user not selected any category for type
    valid() {
      let default_lang_locale = this.$store.getters.languages.find(
        (e) => e.is_default
      ).locale;
      if (!this.editItemObj.name[default_lang_locale]) {
        this.errName = true;
        this.err = true;
        this.tabs = 0;
        this.errText = "Please type the  name in default language";
        return false;
      } else {
        this.errName = false;
      }
      if (this.editItemObj.categories_ids.length == 0) {
        this.errCats = true;
        this.err = true;
        this.tabs = 0;
        this.errText = "Please select 1 category at least";
        return false;
      } else {
        this.errCats = false;
        this.err = false;
        this.errName = false;
      }
      return true;
    },
    // save the edit or create
    save() {
      if (this.valid()) {
        // check if  everything is valid
        this.load = true; // make save button loading
        if (this.indexItem > -1) {
          //  this condetion  to check if user want to edit or create
          let data = JSON.stringify(this.editItemObj);
          var config = {
            method: "put",
            url: `projectTypes/${this.editItemObj.id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${this.$store.state.token}`,
              "Content-Type": "application/json",
            },
            data: data,
          };
          this.axios(config)
            .then((res) => {
              this.$store.dispatch("projectTypes"); // collect the new data after edit
              this.close();
            })
            .catch((err) => {
              this.errorName = true;
              this.load = false;
            });
        } else {
          let data = JSON.stringify(this.editItemObj);
          var config = {
            method: "post",
            url: "projectTypes",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${this.$store.state.token}`,
              "Content-Type": "application/json",
            },
            data: data,
          };
          this.axios(config)
            .then((res) => {
              this.$store.dispatch("projectTypes"); // collect the new data after create
              this.close();
            })
            .catch((err) => {
              this.errorName = true;
              this.load = false;
            });
        }
      }
    },
    close() {
      // close the edit & create window after  saveing or closeing
      this.load = false; // remove save loading
      this.dialog = false; // close window
      this.errorName = false;
      this.errorCats = false;
      this.editedIndex = -1;
      this.$nextTick(() => {
        Object.keys(this.editItemObj).forEach((e) => {
          if (e == "name") {
            this.editItemObj[e] = {};
          } else {
            this.editItemObj[e] = [];
          }
        }); // back the editItemObj to defualt
      });
    },
    // get delete id then assign for prop to use it
    getDeleteId(id) {
      this.deleteId = id;
      this.deleteDialog = true;
    },
    // delete type
    deleteType() {
      this.loadDelete = true; // make delete button start loading
      var config = {
        method: "delete",
        url: `projectTypes/${this.deleteId}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.$store.state.token}`,
          "Content-Type": "application/json",
        },
      };
      this.axios(config).then((res) => {
        let findindex = this.desserts.findIndex((e) => e.id == this.deleteId); // get index of  type delete for delete form desserts
        this.desserts.splice(findindex, 1);
        this.deleteDialog = false;
        this.loadDelete = false;
      });
    },
  },
  mounted() {
    this.$store.dispatch("categories");
    this.$store.dispatch("projectTypes");
    let vm = this;
    window.onkeyup = function (e) {
      // make delete action in enter key
      if (e.key == "Enter" && !vm.loadDelete) {
        vm.deleteType();
      }
    };
  },
};
</script>
