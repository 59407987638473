<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!$store.state.languages">
    </v-skeleton-loader>
    <!-- / loader before data load -->
    <v-card v-if="$store.state.languages" outlined elevation="0" class="mb-10">
      <v-card-title>
        {{ keys.languages_page_name }}
        <v-spacer></v-spacer>
        <v-dialog max-width="500" v-model="dialog">
          <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip bottom nudge-bottom="-5">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-bind="attrs"
                  v-on="{ ...dialog, ...tooltip }"
                  elevation="0"
                  color="primary"
                >
                  {{ keys.languages_page_add_btn }}
                </v-btn>
              </template>
              <span>{{ keys.languages_page_add_btn }}</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="font-weight-bold">{{
              dialogTitle
            }}</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-row class="mb-1">
                <v-col cols="12" md="6">
                  <v-text-field v-model="editItemObj.name" hide-details>
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.languages_page_lang_name }}
                    </template>
                  </v-text-field>
                </v-col>
                <!--/col  name -->
                <v-col cols="12" md="6">
                  <v-text-field v-model="editItemObj.native" hide-details>
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.languages_page_native }}
                    </template>
                  </v-text-field>
                </v-col>
                <!--/col  native name  -->

                <v-col cols="12" md="6">
                  <v-text-field hide-details v-model="editItemObj.locale">
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.languages_page_locale }}
                    </template>
                  </v-text-field>
                </v-col>
                <!--/col  locate  -->
                <v-col cols="12" md="6">
                  <v-select
                    hide-details
                    v-model="editItemObj.direction"
                    :items="['ltr', 'rtl']"
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.languages_page_dir }}
                    </template>
                  </v-select>
                </v-col>
                <!--/col  direction   -->

                <v-col v-show="select" cols="12" md="6">
                  <v-select
                    hide-details
                    v-model="editItemObj.active"
                    :items="selectActive"
                    name="name"
                    item-text="name"
                    item-value="active"
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.name }}
                    </template>
                  </v-select>
                </v-col>
                <!--/col  active    -->
              </v-row>
            </v-card-text>
            <!--/card text -->
            <v-card-actions>
              <v-btn
                elevation="0"
                small
                text
                color="primary"
                :loading="load"
                @click="save()"
                class="font-weight-bold"
              >
                {{ keys.languages_page_popup_save_btn }}
              </v-btn>
              <!--/ save -->

              <v-btn
                @click="close()"
                small
                text
                color="red"
                class="font-weight-bold"
              >
                {{ keys.languages_page_popup_close_btn }}</v-btn
              >
              <!--/close -->
            </v-card-actions>
            <!--/action -->
          </v-card>
        </v-dialog>
        <!--/dialog -->
      </v-card-title>
      <!--/card title -->
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="desserts"
        :mobile-breakpoint="0"
        disable-sort
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-btn
            text
            color="primary"
            :to="{
              name: 'translations',
              query: { locale: item.locale, title: item.native, dir: item.direction},
            }"
          >
            {{ item.name }}
          </v-btn>
        </template>
        <!--/end name item -->
        <template v-slot:[`item.active`]="{ item }">
          <v-chip
            x-small
            class="px-2"
            :class="[
              { primary: item.active },
              { ' red accent-1 white--text': !item.active },
            ]"
          >
            {{ item.active ? "فعال" : "غير فعال" }}
          </v-chip>
        </template>
        <!--/end active item -->
        <template v-slot:[`item.is_default`]="{ item }">
          <v-switch
            :loading="loadDefaultSwith"
            :disabled="item.is_default"
            @change="changeDefault(item)"
            v-model="item.is_default"
          ></v-switch>
        </template>
        <!--/end active item -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="editLanguage(item)" small icon>
            <v-icon small>edit </v-icon>
          </v-btn>
          <!--/edit language -->
          <v-btn @click="getDeleteId(item.id)" icon small>
            <v-icon small>delete </v-icon>
          </v-btn>
          <!--/delete language -->
        </template>
        <!--/ end action items -->
      </v-data-table>
      <!-- / data table -->
    </v-card>
    <!--/card -->

    <v-dialog max-width="250" v-model="deleteLang">
      <v-card>
        <v-card-title class="text-center d-block"
          >{{ keys.languages_page_delete_popup_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="loadDelete"
            :loading="loadDelete"
            @click="deleteLanguage()"
            depressed
            color="error"
            >{{ keys.languages_page_delete_popup_delete_btn }}</v-btn
          >
          <v-btn @click="deleteLang = false" depressed>{{
            keys.languages_page_delete_popup_close_btn
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
</template>

<script>
export default {
  name: "languages",
  data() {
    return {
      tabs: null,
      err: false,
      errText: "",
      switch: false,
      loadDefaultSwith: false,

      idErr: null,
      nameErr: false,
      nativeErr: false,
      localeErr: false,
      directionErr: false,
      errText: "can't be empty",

      load: false,
      select: false,
      selectActive: [
        {
          name: "فعال",
          active: true,
        },
        {
          name: "غير فعال",
          active: false,
        },
      ],
      selectDefault: [
        {
          name: "نعم",
          active: true,
        },
        {
          name: "لا",
          active: false,
        },
      ],
      dialog: false,
      indexItem: -1,
      editItemObj: {
        name: "",
        locale: "",
        native: "",
        direction: null,
        is_default: false,
      },
      defualtItem: {
        name: "",
        locale: "",
        native: "",
        direction: null,
        is_default: false,
      },
      deleteLang: false,
      loadDelete: false,
      deleteId: null,
    };
  },
  watch: {
    dialog(newvalue, oldvalue) {
      if (!newvalue && oldvalue) this.close();
    },
  },
  computed: {
    dialogTitle() {
      return this.indexItem == -1
        ? this.keys.languages_page_popup_add_title
        : this.keys.languages_page_popup_edit_title;
    },
    desserts() {
      return this.$store.state.languages || [];
    },
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    headers() {
      return [
        { text: this.keys.languages_page_num, value: "id", align: "center" },
        {
          text: this.keys.languages_page_lang_name,
          value: "name",
          align: "center",
        },
        {
          text: this.keys.languages_page_native,
          value: "native",
          align: "center",
        },
        {
          text: this.keys.languages_page_locale,
          value: "locale",
          align: "center",
        },
        {
          text: this.keys.languages_page_dir,
          value: "direction",
          align: "center",
        },
        {
          text: this.keys.languages_page_package_status,
          value: "active",
          align: "center",
        },
        {
          text: this.keys.languages_page_package_default,
          value: "is_default",
          align: "center",
        },
        {
          text: this.keys.languages_page_actions,
          value: "actions",
          align: "center",
        },
      ];
    },
  },
  methods: {
    changeDefault(item) {
      if (item.is_default) {
        this.loadDefaultSwith = true;
        let config = {
          method: "post",
          url: `languages/default/${item.id}`,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${this.$store.state.token}`,
            "Content-Type": "application/json",
          },
        };
        this.axios(config).then((res) => {
          this.loadDefaultSwith = false;
          this.$store.dispatch("languages");
        });
      }
    },
    // fade the dialog then geting the Language   item which want to edit  then assign for editITemObj
    editLanguage(item) {
      this.select = true;
      this.indexItem = this.desserts.indexOf(item);
      this.editItemObj = Object.assign({}, item);
      this.dialog = true;
    },
    // make validation before request edit or create if name empty || desc empty
    valid() {
      let re = true;
      Object.entries(this.editItemObj).forEach((e, a) => {
        if (e[1] != "" || typeof e[1] == "boolean") {
          this[`${e[0]}Err`] = false;
          re = true;
        } else {
          this[`${e[0]}Err`] = true;
          re = false;
        }
      });
      return re;
    },

    // save the edit or create
    save() {
      if (this.valid()) {
        // check if  everything is valid
        this.load = true; // make save button loading
        if (this.indexItem > -1) {
          //  this condetion  to check if user want to edit or create
          let data = JSON.stringify(this.editItemObj);
          let config = {
            method: "put",
            url: `languages/${this.editItemObj.id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${this.$store.state.token}`,
              "Content-Type": "application/json",
            },
            data: data,
          };
          this.axios(config)
            .then((res) => {
              this.$store.dispatch("languages");
              this.close();
            })
            .catch((err) => {
              this.errorName = true;
              this.load = false;
            });
        } else {
          let data = JSON.stringify(this.editItemObj);
          let config = {
            method: "post",
            url: "languages",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${this.$store.state.token}`,
              "Content-Type": "application/json",
            },
            data: data,
          };
          this.axios(config)
            .then((res) => {
              this.$store.dispatch("languages"); // adding the new object to render it
              this.close();
            })
            .catch((err) => {
              // this catch resonse the name is exists
              this.errorName = true;
              this.load = false;
            });
        }
      }
    },
    close() {
      // close the edit & create window after  saveing or closeing
      this.load = false; // remove save loading
      this.dialog = false; // close window
      this.errorName = false;
      this.errorDesc = false;
      this.select = false;
      this.editedIndex = -1;
      this.$nextTick(() => {
        this.editItemObj = Object.assign({}, this.defualtItem); // back the editItemObj to defualt
      });
    },
    // get delete id then assign for prop to use it
    getDeleteId(id) {
      this.deleteId = id;
      this.deleteLang = true;
    },
    // delete languages
    deleteLanguage() {
      this.loadDelete = true; // make delete button start loading
      let config = {
        method: "delete",
        url: `languages/${this.deleteId}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.$store.state.token}`,
          "Content-Type": "application/json",
        },
      };
      this.axios(config).then((res) => {
        let findindex = this.desserts.findIndex((e) => e.id == this.deleteId); // get index of  category delete for delete form desserts
        this.desserts.splice(findindex, 1);
        this.deleteLang = false;
        this.loadDelete = false;
      });
    },
  },
  mounted() {
    this.$store.dispatch("languages");
    let vm = this;
    window.onkeyup = function (e) {
      // make delete action in enter key
      if (e.key == "Enter") {
        vm.deleteLanguage();
      }
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input--switch .v-input--selection-controls__input {
    margin: 0 auto;
  }
}
</style>
