<template>
  <v-container>
    <v-card elevation="0" outlined>
      <v-card-title class="font-weight-bold">اعدادت</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" lg="4">
            <v-text-field label="عناوين الصفحات"></v-text-field>
          </v-col>
          <!--/col-->
          <v-col cols="12" sm="6" lg="4">
            <v-file-input
              prepend-icon=""
              append-icon="image"
              label="تغير اللوجو"
            ></v-file-input>
          </v-col>
          <!--/col-->
        </v-row>
      </v-card-text>
    </v-card>

    <div class="text-end">
      <v-tooltip bottom nudge-bottom="-5">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            class="mt-3"
            color="primary"
            elevation="0"
            >حفظ</v-btn
          >
        </template>
        <span>حفظ</span>
      </v-tooltip>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "superSettings",
};
</script>
