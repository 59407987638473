<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!$store.state.prices">
    </v-skeleton-loader>
    <!-- / loader before data load -->

    <v-card v-if="$store.state.prices" outlined elevation="0" class="mb-10">
      <v-card-title>
        {{ keys.prices_page_title }}
        <v-spacer></v-spacer>
        <v-btn :to="{ name: 'new_pricing_page' }" elevation="0" color="primary">
          {{ keys.prices_page_btn_add }}
        </v-btn>
      </v-card-title>
      <!--/card title -->
      <v-data-table :headers="headers" :items="desserts" :mobile-breakpoint="0">
        <template v-slot:[`item.image`]="{ item }">
          <img width="80" :src="item.src" />
        </template>
        <!--/end name item -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :to="{
              name: 'new_pricing_page',
              query: { id: item.id, name: item.type_locale },
            }"
            small
            icon
          >
            <v-icon small>edit </v-icon>
          </v-btn>
          <!--/edit language -->
          <v-btn @click="getDeleteId(item.id)" icon small>
            <v-icon small>delete </v-icon>
          </v-btn>
          <!--/delete language -->
        </template>
        <!--/ end action items -->
      </v-data-table>
      <!-- / data table -->
    </v-card>
    <!--/card -->

    <v-dialog max-width="300" v-model="deletePop">
      <v-card>
        <v-card-title class="text-center d-block"
          >{{ keys.prices_page_popup_delete_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="loadDelete"
            :loading="loadDelete"
            depressed
            color="error"
            @click="deletePrice()"
          >
            {{ keys.prices_page_popup_delete_btn }}
          </v-btn>
          <v-btn @click="deletePop = false" depressed>{{
            keys.prices_page_popup_close_btn
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
</template>

<script>
export default {
  name: "prices",
  data() {
    return {
      deletePop: false,
      loadDelete: false,
      deleteId: null,
    };
  },
  watch: {
    dialog(newvalue, oldvalue) {
      if (!newvalue && oldvalue) {
        this.close();
      }
    },
  },
  computed: {
    desserts() {
      return this.$store.state.prices || [];
    },
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    headers() {
      return [
        {
          text: this.keys.prices_page_type_name,
          value: "type_locale",
          align: "center",
        },
        {
          text: this.keys.prices_page_department,
          value: "locale_department_name",
          align: "center",
        },
        {
          text: this.keys.prices_page_actions,
          value: "actions",
          align: "center",
        },
      ];
    },
  },
  methods: {
    // get delete id then assign for prop to use it
    getDeleteId(id) {
      this.deleteId = id;
      this.deletePop = true;
    },
    // delete languages
    deletePrice() {
      this.loadDelete = true; // make delete
      let config = {
        method: "delete",
        url: `prices/${this.deleteId}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.$store.state.token}`,
          "Content-Type": "application/json",
        },
      };
      this.axios(config).then((res) => {
        let findindex = this.desserts.findIndex((e) => e.id == this.deleteId); // get index of  category delete for delete form desserts
        this.desserts.splice(findindex, 1);
        this.deletePop = false;
        this.loadDelete = false;
      });
    },
  },
  mounted() {
    this.$store.dispatch("prices");
    let vm = this;
    window.onkeyup = function (e) {
      // make delete action in enter key
      if (e.key == "Enter") {
        vm.deletePrice();
      }
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input--switch .v-input--selection-controls__input {
    margin: 0 auto;
  }
}
</style>
