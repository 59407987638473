var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.$store.state.prices)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),(_vm.$store.state.prices)?_c('v-card',{staticClass:"mb-10",attrs:{"outlined":"","elevation":"0"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.keys.prices_page_title)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"to":{ name: 'new_pricing_page' },"elevation":"0","color":"primary"}},[_vm._v(" "+_vm._s(_vm.keys.prices_page_btn_add)+" ")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"width":"80","src":item.src}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{
            name: 'new_pricing_page',
            query: { id: item.id, name: item.type_locale },
          },"small":"","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit ")])],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.getDeleteId(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete ")])],1)]}}],null,true)})],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.deletePop),callback:function ($$v) {_vm.deletePop=$$v},expression:"deletePop"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center d-block"},[_vm._v(_vm._s(_vm.keys.prices_page_popup_delete_title)+" ")]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"disabled":_vm.loadDelete,"loading":_vm.loadDelete,"depressed":"","color":"error"},on:{"click":function($event){return _vm.deletePrice()}}},[_vm._v(" "+_vm._s(_vm.keys.prices_page_popup_delete_btn)+" ")]),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.deletePop = false}}},[_vm._v(_vm._s(_vm.keys.prices_page_popup_close_btn))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }