import axios from "axios";
import VueAxios from "vue-axios";
import Vue from "vue";
import Vuex from "vuex";
Vue.use(VueAxios, axios);
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: localStorage.getItem("locale") || "HE",
    static_translations: [],
    rule: "admin",

    drawer: true,
    // for toggle darkmode
    darkMode: false,
    // toggle the header between Auth pages & public
    noHeader: false,
    dataTableFooterProps: {
      showFirstLastPage: true,
      "items-per-page-text": "لكل صفحة",
    },

    // Auth
    token: localStorage.getItem("token") || null,
    userObject: JSON.parse(localStorage.getItem("userObject")) || null,

    //  packages
    packages: null,
    //  Categories
    categories: null,

    // projectTypes ---
    projectTypes: null,

    //-------- languages
    languages: null,

    //----------- departments
    departments: null,

    //----------- companies
    companies: null,

    //----------- pricies
    prices: null,
    edit_price: null,
  },
  getters: {
    loggedin(state) {
      return state.token != null;
    },
    packages(state) {
      return state.packages != null;
    },
    cats(state) {
      return state.categories || [];
    },
    languages(state) {
      return state.languages || [];
    },
    page_trans_keys: (state) => (page_key) => {
      let page_keys = state.static_translations.find((e) => e.page == page_key);
      return page_keys || [];
    },
  },
  mutations: {
    getTrns(state, static_trans) {
      state.static_translations.push(static_trans);
    },
    removeTrans(state, page_key) {
      let ind = state.static_translations.findIndex((e) => e.page == page_key);
      if (ind > -1) {
        state.static_translations.splice(ind, 1);
      }
    },
    toggleAsideMenu(state) {
      return (state.drawer = !state.drawer);
    },
    login(state, token) {
      state.token = token;
      console.log(token);
    },
    userObj(state, user) {
      state.userObject = user;
    },
    logout(state) {
      state.userObject = null;
      state.token = null;
      localStorage.removeItem("token");
    },
    //packages page -----------------
    packages(state, packages) {
      state.packages = packages;
    },

    //categories -------------------
    categories(state, cats) {
      state.categories = cats;
    },

    // projects types ---------------
    projectTypes(state, projectTypes) {
      state.projectTypes = projectTypes;
    },
    // languages ---------------
    languages(state, languages) {
      state.languages = languages;
    },
    // departments ---------------
    departments(state, departments) {
      state.departments = departments;
    },
    // companies ---------------
    companies(state, companies) {
      state.companies = companies;
    },
    // prices ---------------
    prices(state, prices) {
      state.prices = prices;
    },
  },
  actions: {
    login(context, cred) {
      return new Promise((resolve, reject) => {
        var data = JSON.stringify({
          email: cred.email,
          password: cred.password,
        });
        var config = {
          method: "post",
          url: "/login",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config)
          .then((res) => {
            const token = res.data.data.token;
            const user = res.data.data.user;
            localStorage.setItem("token", token);
            context.commit("login", token);
            context.commit("userObj", user);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // translations
    async get_Static_Translate(context, cred) {
      let data = {
        side: context.state.rule,
        pages: [cred],
      };
      let config = {
        method: "post",
        url: "settings/static_translation/side",
        headers: {
          Accept: "application/json",
          locale: context.state.locale,
          Authorization: `Bearer ${context.state.token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      let res = await axios(config);
      if (res.data[0] != undefined) {
        context.commit("getTrns", res.data[0]);
      }
    },
    // get all packages  -----------------
    packages(context) {
      var config = {
        method: "get",
        url: "packages",
        headers: {
          Accept: "application/json",
          Authorization: `"Bearer ${context.state.token}`,
        },
      };
      axios(config).then((res) => {
        context.commit("packages", res.data);
      });
    },

    // get all categories -----------------
    categories(context) {
      var config = {
        method: "get",
        url: "categories",
        headers: {
          Accept: "application/json",
          Authorization: `"Bearer ${context.state.token}`,
        },
      };
      axios(config).then((res) => {
        context.commit("categories", res.data);
      });
    },

    // get  project types  -----------------
    projectTypes(context) {
      var config = {
        method: "get",
        url: "projectTypes",
        headers: {
          Accept: "application/json",
          Authorization: `"Bearer ${context.state.token}`,
        },
      };
      axios(config).then((res) => {
        context.commit("projectTypes", res.data);
      });
    },

    // get  languages  -----------------
    languages(context) {
      var config = {
        method: "get",
        url: "languages",
        headers: {
          Accept: "application/json",
          Authorization: `"Bearer ${context.state.token}`,
        },
      };
      axios(config).then((res) => {
        let langs = res.data;
        let is_default = res.data.find((e) => e.is_default);
        langs.splice(langs.indexOf(is_default), 1);
        langs.unshift(is_default);
        context.commit("languages", langs);
      });
    },
    // get  departments  -----------------
    departments(context) {
      var config = {
        method: "get",
        url: "departments",
        headers: {
          Accept: "application/json",
          Authorization: `"Bearer ${context.state.token}`,
        },
      };
      axios(config).then((res) => {
        context.commit("departments", res.data);
      });
    },

    // get  companies  -----------------
    companies(context) {
      var config = {
        method: "get",
        url: "companies",
        headers: {
          Accept: "application/json",
          Authorization: `"Bearer ${context.state.token}`,
        },
      };
      axios(config).then((res) => {
        context.commit("companies", res.data);
      });
    },

    // get prices
    async prices(context) {
      var config = {
        method: "get",
        url: "prices",
        headers: {
          Accept: "application/json",
          Authorization: `"Bearer ${context.state.token}`,
        },
      };
      let res = await axios(config);
      context.commit("prices", res.data);
    },
  },
  modules: {},
});
