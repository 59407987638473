<template>
  <v-container>
    <v-card
      elevation="0"
      class="mb-4"
      outlined
      v-for="(item, i) in items"
      :key="i"
    >
      <v-card-title class="d-flex align-center">
        <span> معطيات </span>
        <v-spacer></v-spacer>

        <v-select
          hide-details
          label="المورد"
          :items="['حسن', 'طاهر']"
          class="supplier"
        ></v-select>
        <v-btn
          @click="items.splice(i, 1)"
          elevation="1"
          color="red white--text"
          fab
          small
          v-if="i != 0"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <div
          class="services gray pa-5 mb-3"
          v-for="(s, f) in item.services"
          :key="f"
        >
          <v-select
            hide-details
            label="الخدمات"
            :items="['خذمة 1', 'خدمة2']"
            class="services__select ms-auto"
          ></v-select>
          <v-row v-for="(l, h) in s.lines" :key="h">
            <v-col>
              <v-text-field label="one"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="two"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="three"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="four"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="five"></v-text-field>
            </v-col>
            <v-col style="max-width: 80px">
              <v-btn
                icon
                class="mt-3"
                elevation="0"
                color="red white--text"
                fab
                small
                @click="items[i].services[f].lines.splice(h, 1)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </v-col>
            <!--/col -->
          </v-row>
          <!--/ row -->
          <v-btn
            depressed
            color="primary"
            @click="items[i].services[f].lines.push(0)"
          >
            سطر جديد</v-btn
          >
        </div>
        <v-btn
          depressed
          color="primary"
          class="mt-3"
          @click="items[i].services.push({ lines: [0] })"
          >خدمة جديدة</v-btn
        >
      </v-card-text>
    </v-card>
    <div class="mt-3 d-flex">
      <v-btn @click="items.push(emptySupplier)" color="primary" depressed
        >مورد جديد</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn color="gray darken-2 white--text" depressed>حفظ</v-btn>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
::v-deep {
  .services {
    &__select {
      width: 200px;
    }
  }
  .supplier {
    max-width: 200px !important;
  }
}
</style>
<script>
export default {
  name: "dataA",
  data() {
    return {
      emptySupplier: {
        services: [
          {
            lines: [0],
          },
        ],
      },
      items: [
        {
          services: [
            {
              lines: [0],
            },
          ],
        },
      ],
    };
  },
};
</script>
