<template>
  <v-container>
    <div v-if="edit_loader">
      <v-skeleton-loader
        v-for="(a, i) in new Array(3)"
        :key="i"
        v-bind="loader_attrs"
        type="article, actions"
      ></v-skeleton-loader>
    </div>

    <h3 class="mb-3" v-if="!edit_loader">
      {{
        $route.query.id
          ? `${keys.new_pricing_page_title_edit} (${$route.query.name})`
          : `${keys.new_pricing_page_title_add}`
      }}
    </h3>
    <v-card v-if="!edit_loader" class="mb-5" elevation="0" outlined>
      <v-card-title>
        {{ keys.new_pricing_page_basic_info_title }}
      </v-card-title>
      <!--/card title -->
      <v-card-text>
        <v-alert
          class="text-center"
          v-if="basic_info_errors.alert.prop"
          dense
          border="left"
          type="error"
        >
          {{ basic_info_errors.alert.text }}
        </v-alert>

        <v-tabs class="mb-3" v-model="tabs" background-color="gray">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            v-model="tabs"
            v-for="(lang, i) in this.$store.getters.languages"
            :key="i"
          >
            {{ lang.native }}
          </v-tab>
        </v-tabs>
        <!--/tabs -->

        <v-tabs-items v-model="tabs">
          <v-tab-item
            v-for="(lang, i) in this.$store.getters.languages"
            :key="i"
          >
            <v-row
              :dir="lang.direction"
              :class="[
                { 'text-end': lang.direction == 'ltr' },
                { 'text-start': lang.direction == 'rtl' },
              ]"
              class="pb-1"
            >
              <v-col cols="6" sm="3">
                <v-text-field
                  v-model="price_obj.type[lang.locale]"
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  hide-details
                  :error="tabs == 0 ? basic_info_errors.type : null"
                >
                  <template slot="label">
                    <strong v-if="tabs == 0" class="error--text">*</strong>
                    {{ keys.new_pricing_page_basic_pricing_type }}
                  </template>
                </v-text-field>
              </v-col>
              <!--/ col -->
              <v-col cols="6" sm="3" v-if="tabs == 0">
                <v-select
                  v-model.number="price_obj.department_id"
                  hide-details
                  :items="departments"
                  item-value="id"
                  item-text="name_locale"
                  :error="basic_info_errors.department"
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                >
                  <template slot="label">
                    <strong class="error--text">*</strong>
                    {{ keys.new_pricing_page_basic_departments }}
                  </template>
                </v-select>
              </v-col>
              <!--/ col -->
              <v-col cols="6" sm="3" v-if="tabs == 0">
                <v-select
                  v-model="price_obj.table_width"
                  hide-details
                  :items="table_width"
                  item-text="text"
                  item-value="col"
                  :error="basic_info_errors.table_width"
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                >
                  <template slot="label">
                    <strong class="error--text">*</strong>
                    {{ keys.new_pricing_page_basic_table_width }}
                  </template>
                </v-select>
              </v-col>
              <!--/ col -->
              <v-col cols="6" sm="3">
                <v-textarea
                  :label="keys.new_pricing_page_basic_note"
                  rows="1"
                  hide-details
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                ></v-textarea>
              </v-col>
              <!--/ col -->
            </v-row>
            <!--/row -->
          </v-tab-item>
        </v-tabs-items>
        <v-switch
          @change="changeSwap($event)"
          hide-details
          v-model="price_obj.has_price"
        >
          <template slot="label" class="d-inline-block">
            <span>
              {{
                price_obj.has_price
                  ? keys.new_pricing_page_basic_info_switch_with_pricing
                  : keys.new_pricing_page_basic_info_switch_without_pricing
              }}
            </span>
          </template>
        </v-switch>
      </v-card-text>
      <!--/card text -->
    </v-card>
    <!--/card -->

    <fields
      v-if="!edit_loader"
      ref="fields_comp"
      :fields="fields_items"
      @new_field="new_fields($event)"
      @remove_field="remove_row($event)"
      @field_desc="row_name($event)"
      @filed_type="selects($event)"
      @field_is_required="selects($event)"
      :translations="fields_trans"
      :test="test"
    ></fields>
    <!--/fields compontent  -->

    <additions
      ref="additions_comp"
      :additions="price_obj"
      @set_additons_group="set_additons_group($event)"
      @new_group="new_group()"
      @remove_group_addition="remove_group_addition($event)"
      @row_name="row_name($event)"
      @remove_addition="remove_additon($event)"
      @addition_name="set_additions($event)"
      @related_to="additions_selects($event)"
      @total_to_table="additions_selects($event)"
      :translations="additions_trans"
      v-if="price_obj.has_price && !edit_loader"
    ></additions>
    <!--/addations compontent  -->
    <v-btn
      :loading="saveLoad"
      @click="save()"
      color="primary"
      class="float-end mb-5"
      depressed
      v-if="!edit_loader"
      >{{ keys.new_pricing_page_basic_info_save_btn }}</v-btn
    >

    <v-snackbar width="auto" :left="rtl" :right="!rtl" v-model="snackbar.prop">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-icon color="primary" v-bind="attrs">done</v-icon>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import fields from "@/components/new_pricing/fields";

import additions from "@/components/new_pricing/additions";

export default {
  name: "new_pricing",
  data() {
    return {
      edit_loader: false,
      loader_attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 0,
      },
      test: {
        name: "hassan",
      },
      snackbar: {
        prop: false,
        text: "success",
      },
      saveLoad: false,
      basic_info_errors: {
        alert: {
          prop: false,
          text: "",
        },
        type: false,
        department: false,
        table_width: false,
      },
      tabs: 0,
      switch_prop: true,
      table_width: [
        {
          col: "6",
          text: "50%",
        },
        {
          col: "12",
          text: "100%",
        },
      ],
      price_obj: {
        type: {},
        department_id: null,
        table_width: "",
        has_price: true,
        fields: [],
        additions: [
          {
            name: {},
            is_public: true,
            err_group_name: false,
            children: [],
          },
        ],
      },

      field_err: {
        desc: false,
        type: false,
        is_req: false,
      },
    };
  },
  computed: {
    fields_items() {
      return Object.assign({}, this.price_obj);
    },
    rtl() {
      return this.$vuetify.rtl;
    },
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    fields_trans() {
      // fields's component translations
      return {
        title: this.keys.new_pricing_page_fields_title,
        field: this.keys.new_pricing_page_fields_field,
        new_btn: this.keys.new_pricing_page_fields_field_btn_new,

        field_type: {
          label: this.keys.new_pricing_page_fields_field_type,
          items: [
            {
              type: "number",
              text: this.keys.new_pricing_page_fields_field_number,
            },
            {
              type: "text",
              text: this.keys.new_pricing_page_fields_field_text,
            },
            {
              type: "file",
              text: this.keys.new_pricing_page_fields_field_file,
            },
          ],
        },
        field_status: {
          label: this.keys.new_pricing_page_fields_field_status,
          items: [
            {
              text: this.keys.new_pricing_page_fields_field_status_required,
              is_required: true,
            },
            {
              text: this.keys.new_pricing_page_fields_field_status_optional,
              is_required: false,
            },
          ],
        },
      };
    },

    additions_trans() {
      // additions's component translations
      return {
        group_title: this.keys.new_pricing_page_additions_public_group_title,
        title: this.keys.new_pricing_page_additions_title,
        field_label: this.keys.new_pricing_page_additions_field_label,
        new_btn: this.keys.new_pricing_page_additions_new_btn,
        quan_public: {
          label: this.keys.new_pricing_page_additions_quantity,
          items: [
            {
              text: this.keys.new_pricing_page_additions_quantity_related,
              related_to: 1,
            },
            {
              text: this.keys.new_pricing_page_additions_quantity_not_related,
              related_to: 2,
            },
            {
              text: this.keys.new_pricing_page_additions_quantity_independent,
              related_to: 3,
            },
          ],
        },
        quan_privet: {
          items: [
            {
              text: this.keys.new_pricing_page_additions_quantity_related,
              related_to: 1,
            },
            {
              text: this.keys.new_pricing_page_additions_quantity_not_related,
              related_to: 2,
            },
          ],
        },
        total: {
          label: this.keys.new_pricing_page_additions_total,
          items: [
            {
              text: this.keys.new_pricing_page_additions_add_table,
              total_to_table: true,
            },
            {
              text: this.keys.new_pricing_page_additions_dont_add_table,
              total_to_table: false,
            },
          ],
        },
      };
    },
    departments() {
      return this.$store.state.departments || [];
    },
  },
  methods: {
    new_fields(e) {
      let field = {
        err: {
          desc: false,
          type: false,
          is_req: false,
        },
        description: {},
        type: "",
        is_required: null,
      };

      this.price_obj[e.section].push(field);
    },
    remove_row(e) {
      this.price_obj[`${e.section}`].splice(e.indx, 1);
      if (e.id != undefined) {
        let config = {
          method: "delete",
          url: `prices/${e.section}/${e.id}`,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${this.$store.state.token}`,
            "Content-Type": "application/json",
          },
        };
        this.axios(config);
      }
    },

    set_additons_group(ev) {
      this.$set(this.price_obj.additions, ev.group_index, ev.group);
    },
    new_group() {
      let group = {
        name: {},
        is_public: false,
        err_group_name: false,
        children: [],
      };
      let group_index = this.price_obj.additions.length;
      this.$set(this.price_obj.additions, group_index, group);
    },
    remove_group_addition(ev) {
      this.price_obj.additions.splice(ev.addition_index, 1);
      if ((ev.id = undefined)) {
        console.log(ev);
      }
    },
    remove_additon(e) {
      this.price_obj.additions[e.addition_index].children.splice(
        e.child_index,
        1
      );
      if (e.id != undefined) {
        let config = {
          method: "delete",
          url: `prices/additions/${e.id}`,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${this.$store.state.token}`,
            "Content-Type": "application/json",
          },
        };
        this.axios(config);
      }
    },
    set_additions(e) {
      this.$set(
        this.price_obj[`${e.section}`][e.addition_index].children[
          e.child_index
        ][e.prop_name],
        e.locale,
        e.value
      );
    },
    row_name(e) {
      this.$set(
        this.price_obj[`${e.section}`][e.ind][e.prop_name],
        e.locale,
        e.value
      );
    },
    additions_selects(e) {
      this.$set(
        this.price_obj[`${e.section}`][e.addition_index].children[
          e.child_index
        ],
        e.prop_name,
        e.value
      );
    },
    selects(e) {
      this.price_obj[`${e.section}`][e.ind][e.prop_name] = e.value;
    },
    changeSwap(e) {
      if (e) {
        this.$set(this.price_obj.additions, 0, {
          name: {},
          is_public: true,
          err_group_name: false,
          children: [],
        });
      } else {
        this.$set(this.price_obj, "additions", []);
      }
    },
    basic_validation() {
      let errors = [];
      let default_lang_locale = this.$store.getters.languages.find(
        (e) => e.is_default
      ).locale;
      if (!this.price_obj.type[default_lang_locale]) {
        this.basic_info_errors.type = true;
        errors.push(0);
      } else {
        this.basic_info_errors.type = false;
      }

      if (!this.price_obj.department_id) {
        this.basic_info_errors.department = true;
        errors.push(0);
      } else {
        this.basic_info_errors.department = false;
      }

      if (!this.price_obj.table_width) {
        this.basic_info_errors.table_width = true;
        errors.push(0);
      } else {
        this.basic_info_errors.table_width = false;
      }

      if (errors.length != 0) {
        this.tabs = 0;
        this.saveLoad = false;
        this.basic_info_errors.alert.prop = true;
        this.basic_info_errors.alert.text = "برجاء ملئ المطلوب";
        return false;
      } else {
        this.basic_info_errors.alert.prop = false;
        return true;
      }
    },
    fields_validation() {
      let errors = [];
      let default_lang_locale = this.$store.getters.languages.find(
        (e) => e.is_default
      ).locale;
      Object.entries(this.price_obj.fields).forEach((e, i) => {
        if (!e[1].description[default_lang_locale]) {
          this.price_obj.fields[i].err.desc = true;
          errors.push(i);
        } else {
          this.price_obj.fields[i].err.desc = false;
        }
        if (!e[1].type) {
          this.price_obj.fields[i].err.type = true;
          errors.push(i);
        } else {
          this.price_obj.fields[i].err.type = false;
        }
        if (e[1].is_required == null) {
          this.price_obj.fields[i].err.is_req = true;
          errors.push(i);
        } else {
          this.price_obj.fields[i].err.is_req = false;
        }
      });
      if (errors.length != 0) {
        this.$refs.fields_comp.vaild(true);
        this.saveLoad = false;
        return false;
      } else {
        this.$refs.fields_comp.vaild(false);
        return true;
      }
    },
    check_validations() {
      return {
        basic: this.basic_validation(),
        fields: this.fields_validation(),
      };
    },
    save() {
      this.saveLoad = true;
      if (this.check_validations().basic && this.check_validations().fields) {
        let data = JSON.parse(JSON.stringify(this.price_obj));
        data.additions.forEach((e, i) => {
          if (e.children.length == 0) {
            data.additions.splice(i, 1);
          }
        });
        // console.log(this.price_obj.additions);
        console.log(data);
        let id = this.$route.query.id;
        let config = {
          method: id ? "put" : "post",
          url: id ? `prices/${this.price_obj.id}` : `prices`,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${this.$store.state.token}`,
            "Content-Type": "application/json",
          },
          data: data,
        };
        this.axios(config)
          .then((res) => {
            let vm = this;
            this.saveLoad = false;
            this.snackbar.prop = true;
            console.log(res);
            setTimeout(() => {
              vm.$router.push({ name: "prices_page" });
            }, 2000);
          })
          .catch((err) => {
            console.log(err.response);
            this.saveLoad = false;
          });
      }
    },
    async init_edit(e) {
      let id = e.id;
      let item;
      if (!this.$store.state.price) {
        await this.$store.dispatch("prices");
      }
      item = this.$store.state.prices.find((el) => el.id == id);

      this.$set(this, "price_obj", item);
      this.edit_loader = false;
      console.log(item);
    },
  },

  components: {
    fields,
    additions,
  },
  mounted() {
    this.$store.dispatch("departments");
  },
  created() {
    let query = this.$route.query;
    if (Object.keys(this.$route.query).length != 0) {
      this.edit_loader = true;
      this.init_edit(query);
    }
  },
};
</script>
