<template>
  <v-container>
    <v-card elevation="0" outlined>
      <v-card-title class="font-weight-bold">
        {{ keys.new_package_page_name }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-tabs class="mb-3" v-model="tabs" background-color="gray">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            v-model="tabs"
            v-for="(lang, i) in this.$store.getters.languages"
            :key="i"
          >
            {{ lang.native }}
          </v-tab>
        </v-tabs>
        <!--/tabs -->

        <form ref="form">
          <v-tabs-items v-model="tabs">
            <v-tab-item
              v-for="(lang, i) in this.$store.getters.languages"
              :key="i"
            >
              <v-row
                class="align-start"
                :dir="lang.direction"
                :class="[
                  { 'text-end': lang.direction == 'ltr' },
                  { 'text-start': lang.direction == 'rtl' },
                ]"
              >
                <v-col md="4" cols="12" class ="mb-1">
                  <v-text-field
                    v-model="data.name[lang.locale]"
                    hide-details
                    required
                    :error="error"
                    :class="[
                      { 'right-label': lang.direction == 'rtl' },
                      { 'left-label': lang.direction == 'ltr' },
                    ]"
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.new_package_page_package_name }}
                    </template>
                  </v-text-field>
                </v-col>
                <!--/ col -->
                <v-col md="4" cols="12" v-if="tabs == 0 ">
                  <v-text-field
                    v-model.number="data.month_price"
                    hide-details
                    type="number"
                    min="0"
                    :class="[
                      { 'right-label': lang.direction == 'rtl' },
                      { 'left-label': lang.direction == 'ltr' },
                    ]"
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.new_package_page_package_month_price }}
                    </template>
                  </v-text-field>
                </v-col>
                <!--/ col -->
                <v-col md="4" cols="12" v-if="tabs == 0 ">
                  <v-text-field
                    type="number"
                    v-model.number="data.annual_price"
                    hide-details
                    label="السعر السنوي"
                    min="0"
                    :class="[
                      { 'right-label': lang.direction == 'rtl' },
                      { 'left-label': lang.direction == 'ltr' },
                    ]"
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.new_package_page_package_annual_price }}
                    </template>
                  </v-text-field>
                </v-col>
                <!--/ col -->
                <v-col cols="9" v-if="tabs == 0 ">
                  <v-autocomplete
                    :items="items"
                    v-model="selected"
                    chips
                    item-text="name"
                    item-value="id"
                    multiple
                    return-object
                    @change="changed"
                    :class="[
                      { 'right-label': lang.direction == 'rtl' },
                      { 'left-label': lang.direction == 'ltr' },
                    ]"
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.new_package_page_package_recipients }}
                    </template>
                    <template v-slot:selection="{ item, attrs }">
                      <v-chip @click:close="remove(item)" v-bind="attrs" close>
                        {{ item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="item">
                      <v-list-item-content @click="selectedItem(item)">
                        <v-checkbox
                          hide-details=""
                          class="ma-0"
                          v-model="item.item.selected"
                          :label="item.item.name"
                        ></v-checkbox>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--/ col -->
                <v-col cols="3" v-if="tabs == 0 ">
                  <v-switch
                    v-model="data.is_active"
                    :label="`${keys.new_package_page_package_status} (${
                      data.is_active
                        ? keys.new_package_page_package_status_active
                        : keys.new_package_page_package_status_not_active
                    })`"
                  >
                  </v-switch>
                </v-col>
                <!--/ col -->
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </form>
      </v-card-text>
    </v-card>
    <v-btn
      :loading="loading"
      @click="sendPackage()"
      class="mt-3"
      large
      color="primary"
      depressed
    >
      {{ keys.new_package_page_package_save_btn }}
    </v-btn>

    <v-snackbar width="auto" right v-model="snackbar">
      تم اضافة الخطة بنجاح
      <template v-slot:action="{ attrs }">
        <v-icon color="primary" v-bind="attrs">check_circle</v-icon>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "addPackages",
  data() {
    return {
      tabs: null,

      loading: false,
      switchProp: false,
      selected: [],
      lastItem: null,
      snackbar: false,
      error: false,
      errorText: "",
      data: {
        name: {},
        month_price: 0,
        annual_price: 0,
        is_active: false,
      },
      items: [
        {
          name: "كل جروب 1 ",
          all: true,
          group: "group1",
          id: 22,
          selected: false,
        },
        {
          name: "اسم الرزمة1",
          all: false,
          group: "group1",
          id: 1,
          selected: false,
        },
        {
          name: "اسم الرزمة2",
          all: false,
          group: "group1",
          id: 2,
          selected: false,
        },
        {
          name: "3اسم الرزمة",
          all: false,
          group: "group1",
          id: 3,
          selected: false,
        },
        { divider: true },
        {
          name: "كل جروب 2 ",
          all: true,
          group: "group2",
          id: 33,
          selected: false,
        },
        {
          name: "اسم الرزمة2",
          all: false,
          group: "group2",
          id: 4,
          selected: false,
        },
        {
          name: "اسم الرزمة2",
          all: false,
          group: "group2",
          id: 5,
          selected: false,
        },
        {
          name: "2اسم الرزمة",
          all: false,
          group: "group2",
          id: 6,
          selected: false,
        },
      ],
    };
  },
  computed: {
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
  },
  methods: {
    remove(item) {
      this.selected.splice(this.selected.indexOf(item), 1);
    },

    changed() {
      let item = this.items[this.items.indexOf(this.lastItem)];
      let s = this.selected.filter((v) => v.group == item.group);
      let i = this.items.filter((v) => v.group == item.group && !v.all);
      let group = this.selected.find((v) => v.group == item.group && v.all);
      let itemGroup = this.items.find((v) => v.group == item.group && v.all);
      if (!this.selected.includes(group)) {
        if (s.length == i.length) {
          this.selectGroup(itemGroup);
        }
      } else {
        this.items[this.items.indexOf(group)].selected = false;
        this.selected.splice(this.selected.indexOf(group), 1);
      }
    },
    selectedItem(item) {
      this.lastItem = item.item;
      if (item.item.all) {
        this.selectGroup(item.item);
      } else {
        if (!this.selected.includes(item.item)) {
          this.items[this.items.indexOf(item.item)].selected = true;
        } else {
          this.items[this.items.indexOf(item.item)].selected = false;
        }
      }
    },
    selectGroup(item) {
      if (!this.selected.includes(item)) {
        this.items[this.items.indexOf(item)].selected = true;
        let a = this.items.filter((v) => v.group == item.group);
        a.forEach((e) => {
          this.items[this.items.indexOf(e)].selected = false;
          this.selected.splice(this.selected.indexOf(e), 1);
        });
        a.forEach((e) => {
          this.items[this.items.indexOf(e)].selected = true;
          this.selected.push(e);
        });
      } else {
        this.items[this.items.indexOf(item)].selected = false;
        let a = this.selected.filter((v) => v.group == item.group);
        a.forEach((e) => {
          this.items[this.items.indexOf(e)].selected = false;
          this.selected.splice(this.selected.indexOf(e), 1);
        });
      }
    },
    remove(item) {
      if (item.all) {
        this.selectGroup(item);
      } else {
        this.items[this.items.indexOf(item)].selected = false;
        this.selected.splice(this.selected.indexOf(item), 1);
        let itemGroup = this.items.find((v) => v.group == item.group && v.all);
        if (this.selected.includes(itemGroup)) {
          this.items[this.items.indexOf(itemGroup)].selected = false;
          this.selected.splice(this.selected.indexOf(itemGroup), 1);
        }
      }
    },
    valid() {
      let default_lang_locale = this.$store.getters.languages.find(
        (e) => e.is_default
      ).locale;
      if (!this.data.name[default_lang_locale]) {
        this.tabs = 0;
        this.error = true;
        this.errorText = "برجاء كتابة اسم الرزمة";
        return false;
      } else {
        this.error = false;
        return true;
      }
    },
    sendPackage() {
      if (this.valid()) {
        this.loading = true;
        var data = JSON.stringify(this.data);
        var config = {
          method: "post",
          url: "packages",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${this.$store.state.token}`,
            "Content-Type": "application/json",
          },
          data: data,
        };
        this.axios(config)
          .then((res) => {
            this.snackbar = true;
            this.error = false;
            setTimeout(() => {
              this.$router.push("/packages");
            }, 2000);
          })
          .catch((err) => {
            console.log(err.response);
            this.error = true;
            this.errorText = "هذا الاسم موجود مسبقا ";
          });
      }
    },
    resetInpus() {
      Object.keys(this.data).forEach((a, i) => {
        if (a != "is_active") {
          this.data[a] = null;
        } else {
          this.data[a] = false;
        }
      });
    },
  },
};
</script>
