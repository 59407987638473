import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

import SuperDashboard from "../views/dashboard.vue";
import Companies from "../views/Companies.vue";
import Employees from "../views/employees.vue";
import packages from "../views/packages.vue";
import addPackages from "../views/addPackages.vue";
import superSettings from "../views/Super-admin-settings.vue";
import viewCompaniesPlans from "../views/view-companies-plans.vue";
import payments from "../views/payments.vue";
import typeOfProjects from "../views/Type-of-projects.vue";
import categories from "../views/categories.vue";
import languages from "../views/languages.vue";
import translations from "../views/translations.vue";
import dataA from "../views/data.vue";
import employeesPermissions from "../views/employeesPermissions.vue";
import departments from "../views/departments.vue";
import prices from "../views/prices.vue";

import new_pricing from "../views/new_pricing.vue";
import messages from "../views/messages.vue";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  //--------------------------- super admin

  {
    path: "/Dashboard",
    name: "Dashboard",
    component: SuperDashboard,
    meta: {
      title: "Super Admin Dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/Companies",
    name: "compaines_page",
    component: Companies,
    meta: {
      title: "Companies",
      requiresAuth: true,
    },
  },
  {
    path: "/Employees",
    name: "Employees",
    component: Employees,
    meta: {
      title: "Employees",
      requiresAuth: true,
    },
  },
  {
    path: "/packages",
    name: "packages_page",
    component: packages,
    meta: {
      title: "packages",
      requiresAuth: true,
    },
  },
  {
    path: "/addPackages",
    name: "new_package_page",
    component: addPackages,
    meta: {
      title: "addPackages",
      requiresAuth: true,
    },
  },
  {
    path: "/superSettings",
    name: "superSettings",
    component: superSettings,
    meta: {
      title: "superSettings",
      requiresAuth: true,
    },
  },
  {
    path: "/messages",
    name: "messages",
    component: messages,
    meta: {
      title: "messages",
      requiresAuth: true,
    },
  },
  {
    path: "/viewCompaniesPlans",
    name: "viewCompaniesPlans",
    component: viewCompaniesPlans,
    meta: {
      title: "viewCompaniesPlans",
      requiresAuth: true,
    },
  },
  {
    path: "/payments",
    name: "payments",
    component: payments,
    meta: {
      title: "payments",
      requiresAuth: true,
    },
  },
  {
    path: "/projectTypes",
    name: "projects_types_page",
    component: typeOfProjects,
    meta: {
      title: "typeOfProjects",
      requiresAuth: true,
    },
  },
  {
    path: "/categories",
    name: "categories",
    component: categories,
    meta: {
      title: "categories",
      requiresAuth: true,
    },
  },
  {
    path: "/languages",
    name: "languages_page",
    component: languages,
    meta: {
      title: "languages",
      requiresAuth: true,
    },
  },
  {
    path: "/translations",
    name: "translations",
    component: translations,
    meta: {
      title: "translations",
      requiresAuth: true,
    },
  },
  {
    path: "/data",
    name: "data",
    component: dataA,
    meta: {
      title: "data",
      requiresAuth: true,
    },
  },
  {
    path: "/employeesPermissions",
    name: "employeesPermissions",
    component: employeesPermissions,
    meta: {
      title: "employeesPermissions",
      requiresAuth: true,
    },
  },
  {
    path: "/departments",
    name: "departments",
    component: departments,
    meta: {
      title: "departments",
      requiresAuth: true,
    },
  },
  {
    path: "/new_pricing",
    name: "new_pricing_page",
    component: new_pricing,
    meta: {
      title: "new pricing",
      requiresAuth: true,
    },
  },
  {
    path: "/prices",
    name: "prices_page",
    component: prices,
    meta: {
      title: "new price",
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      title: "Super Admin Login",
      public: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;

  if (to.matched.some((record) => record.meta.public)) {
    if (!store.getters.loggedin) {
      next();
    } else {
      next("/Companies");
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    await store.dispatch("get_Static_Translate", to.name);
    if (!store.getters.loggedin) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  }
});
