<template>
  <div>
    <v-container>
      <h3 class="mb-4">
        Translate to <span class="primary--text">({{ title }})</span>
      </h3>
      <pre>
      {{ translations }}
    </pre
      >

      <v-card elevation="0" class="mb-5">
        <v-card-title>Admin pages </v-card-title>
        <v-data-table
          mobile-breakpoint="0"
          class="mb-5"
          :headers="thead"
          :items="admin_pages"
        >
          <template v-slot:[`item.page`]="{ item }">
            <v-btn
              color="primary"
              @click="pageTranslations(item)"
              text
              class="text-capitalize"
              :loading="item.loading"
            >
              {{ item.page }}
            </v-btn>
            <!--/dialog -->
          </template>
          <!--/template -->
        </v-data-table>
        <!--/ end data table -->
      </v-card>

      <v-card elevation="0">
        <v-card-title>Companies pages </v-card-title>
        <v-data-table
          mobile-breakpoint="0"
          class="mb-5"
          :headers="thead"
          :items="company_pages"
        >
          <template v-slot:[`item.page`]="{ item }">
            <v-btn
              color="primary"
              @click="pageTranslations(item)"
              text
              class="text-capitalize"
              :loading="item.loading"
            >
              {{ item.page }}
            </v-btn>
            <!--/dialog -->
          </template>
          <!--/template -->
        </v-data-table>
        <!--/ end data table -->
      </v-card>

      <v-dialog scrollable max-width="600" v-model="dialog">
        <v-card>
          <v-card-title>
            {{ dialogTitle }}
          </v-card-title>
          <v-divider></v-divider>
          <!--/card title -->
          <v-card-text>
            <div
              v-for="(section, i) in translations.pages.sections"
              :key="i"
              class="mb-4"
            >
              <h3 class="mt-3 black--text text-capitalize mb-2">
                {{ section.section_key }}
              </h3>
              <v-row :style="[{ direction: dir }]">
                <v-col
                  class="pa-2"
                  cols="12"
                  sm="6"
                  v-for="(inp, x) in section.keys"
                  :key="x"
                >
                  <v-text-field
                    v-model="translations.pages.sections[i].keys[x].value"
                    hide-details
                    :class="[
                      { 'right-label': dir == 'rtl' },
                      { 'left-label': dir == 'ltr' },
                    ]"
                    :label="inp.label"
                  ></v-text-field>
                </v-col>
                <!--/ col-->
              </v-row>
              <!--/row -->
            </div>
          </v-card-text>
          <!--/card body-->
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn @click="save()" :loading="laodSavd" color="primary" depressed
              >Save</v-btn
            >
            <v-btn @click="dialog = false" color="secondary" text>Close</v-btn>
          </v-card-actions>
          <!--/ v-card action -->
        </v-card>
      </v-dialog>

      <!--end/   translations  dialog -->

      <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            loading....
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!--/loading dialog -->
    </v-container>
  </div>
</template>

<script>
import static_trans from "../mixins/static_trans";
export default {
  mixins: [static_trans],
  name: "translations",
  data() {
    return {
      obj: {
        price_name: "",
        department: "",
        note: "",
        col: 6,
        fields: [
          {
            trans: {
              ar: "",
              en: "",
            },
            type: "number-",
          },
          {
            trans: {
              ar: "",
              en: "",
            },
            type: "number-",
            total: false,
          },
        ],
      },
      laodSavd: false,
      loading: false,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 0,
      },
      title: "",
      locale: "",
      dir: "",
      dialogTitle: "",
      itemIndex: 0,
      page_key: {
        pages: [],
      },
      dialog: false,
      thead: [
        // header of table
        { text: "number", value: "id", align: "center" },
        { text: "page", value: "page", align: "center" },
      ],
      company_pages: [
        {
          id: 1,
          page: "Companies",
          key: "c_companies_page",
          loading: false,
          side: "company",
        },
        {
          id: 2,
          page: "new company",
          key: "c_add_companies_page",
          loading: false,
          side: "company",
        },
        {
          id: 3,
          page: "new service",
          key: "c_add_services_page",
          loading: false,
          side: "company",
        },
        {
          id: 4,
          page: "branches",
          key: "c_branches_page",
          loading: false,
          side: "company",
        },
        {
          id: 5,
          page: "Service Date",
          key: "services_data_page",
          loading: false,
          side: "company",
        },
        {
          id: 6,
          page: "Send Date",
          key: "send_service_data_page",
          loading: false,
          side: "company",
        },
        {
          id: 7,
          page: "Project",
          key: "project_page",
          loading: false,
          side: "company",
        },
        {
          id: 8,
          page: "Projects",
          key: "projects_page",
          loading: false,
          side: "company",
        },
        {
          id: 9,
          page: "New checks",
          key: "new_check_page",
          loading: false,
          side: "company",
        },
        {
          id: 10,
          page: "Projects files",
          key: "projects_files_page",
          loading: false,
          side: "company",
        },
        {
          id: 11,
          page: "Project file",
          key: "project_file_page",
          loading: false,
          side: "company",
        },
        {
          id: 12,
          page: "Sales companies",
          key: "sales_companies_page",
          loading: false,
          side: "company",
        },
        {
          id: 13,
          page: "Employees",
          key: "employees_page",
          loading: false,
          side: "company",
        },
        {
          id: 14,
          page: "Employee profile",
          key: "employee_profile_page",
          loading: false,
          side: "company",
        },
        {
          id: 15,
          page: "Employee companies requests",
          key: "companies_requests_page",
          loading: false,
          side: "company",
        },
        {
          id: 16,
          page: "Work times",
          key: "work_times_page",
          loading: false,
          side: "company",
        },
        {
          id: 17,
          page: "Add times",
          key: "times_page",
          loading: false,
          side: "company",
        },
        {
          id: 18,
          page: "Payments",
          key: "payments_page",
          loading: false,
          side: "company",
        },
        {
          id: 19,
          page: "Employee  requests",
          key: "employee_requests_page",
          loading: false,
          side: "company",
        },
        {
          id: 20,
          page: "External projects",
          key: "external_project_page",
          loading: false,
          side: "company",
        },
        ,
      ],
      admin_pages: [
        // tbody for  pages
        {
          id: 1,
          page: "Categories",
          key: "categories",
          loading: false,
          side: "admin",
        },
        {
          id: 2,
          page: "Projects Types",
          key: "projects_types_page",
          loading: false,
          side: "admin",
        },
        {
          id: 3,
          page: "Packages",
          key: "packages_page",
          loading: false,
          side: "admin",
        },
        {
          id: 4,
          page: "new package",
          key: "new_package_page",
          loading: false,
          side: "admin",
        },
        {
          id: 5,
          page: "Languages",
          key: "languages_page",
          loading: false,
          side: "admin",
        },
        {
          id: 6,
          page: "departments",
          key: "departments",
          loading: false,
          side: "admin",
        },
        {
          id: 7,
          page: "companies",
          key: "compaines_page",
          loading: false,
          side: "admin",
        },
        {
          id: 8,
          page: "new pricing",
          key: "new_pricing_page",
          loading: false,
          side: "admin",
        },
      ],
      translations: { pages: {} },
    };
  },
  methods: {
    // get the page translations
    getTranslations(side) {
      let data = JSON.stringify(this.page_key);
      let config = {
        method: "post",
        url: "settings/static_translation/all",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.$store.state.token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      this.axios(config).then((res) => {
        this.loading = false; // close loading dialog
        this.dialog = true; // open translations dialog
        this.translations.pages = res.data[0];
        this[`${side}_pages`][this.itemIndex].loading = false;
        this.translations.pages.side = side;
        this.translations.pages.locale = "ar";
        console.log(res.data[0]);
      });
    },
    // get the key of page to prepare it for getting page translations & set the dialog title with page name
    pageTranslations(item) {
      let ind = this[`${item.side}_pages`].findIndex((e) => e == item); // get index of item on tobdy array
      this.itemIndex = ind; // assign index to use afer response
      this[`${item.side}_pages`][ind].loading = true; // make btn loading in clicking

      this.page_key.pages = [];
      this.page_key.pages.push(item.key); // push key  to get translations

      this.dialogTitle = item.page; // asside title from dialog title
      this.loading = true; // open loading dialog
      this.getTranslations(item.side); // call the method to get translate
    },
    save() {
      this.laodSavd = true;
      let data = JSON.stringify(this.translations);
      let config = {
        method: "post",
        url: "settings/static_translation",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.$store.state.token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      this.axios(config).then((res) => {
        let page_key = this.translations.pages.page_key;
        this.$store.commit("removeTrans", page_key);
        this.dialog = false;
        this.laodSavd = false;
      });
    },
  },

  mounted() {
    if (Object.keys(this.$route.query).length > 2) {
      this.title = this.$route.query.title; // set page title
      this.locale = this.$route.query.locale; // get locale translate
      this.dir = this.$route.query.dir;
    } else {
      this.$router.push("/languages"); // back to languages view if there are no two query strings
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-card {
    direction: ltr;
    text-align: left;
  }
}
</style>
